import React from 'react';
import ForgotPasswordSent from '../../components/auth/ForgotPasswordSent';
import ForgotPasswordForm from '../../components/auth/ForgotPasswordForm';
import { useAuth } from '../../contexts/AuthContext';
import FooterLessLayout from '../../layouts/FooterLessLayout';

const ForgotPasswordPage = () => {
  const { forgot_password_requested } = useAuth();

  return (
    <FooterLessLayout>
      {forgot_password_requested ? <ForgotPasswordSent /> : <ForgotPasswordForm />}
    </FooterLessLayout>
  );
};

export default ForgotPasswordPage;
