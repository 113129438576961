import { useHistory } from 'react-router-dom';
import RideTypeWalk from '../../assets/ride_type_walk.svg';
import RideTypeBus from '../../assets/ride_type_bus.svg';
import RideTypeTaxi from '../../assets/ride_type_taxi.svg';
import RideTypeCar from '../../assets/ride_type_car.svg';
import React, { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import { Box, Checkbox } from '@mui/material';
import { RIDE_TYPES } from '../../helpers/consts';

const RouteTypeIcon = ({ type, checked = true }) => {
  let src;
  if (type === 'walk') src = RideTypeWalk;
  if (type === 'bus') src = RideTypeBus;
  if (type === 'taxi') src = RideTypeTaxi;
  if (type === 'car') src = RideTypeCar;

  return (
    <Avatar
      src={src}
      variant="square"
      sx={{ width: 44, height: 30, filter: checked ? 'grayscale(0)' : 'grayscale(1)' }}
    />
  );
};

const RideTypesBar = ({
  rideTypes = RIDE_TYPES,
  readOnly = true,
  checkedState,
  handleChange,
  justifyContent,
}) => {
  const history = useHistory();

  useEffect(() => {
    initRideTypesFilter();
  }, [history.location.search]);

  function initRideTypesFilter() {
    const search = new URLSearchParams(history.location.search);
    if (search.get('ride_types') && handleChange) {
      handleChange((prev) => {
        return search
          .get('ride_types')
          .split(',')
          .reduce((acc, type) => {
            return { ...acc, [type]: true };
          }, prev);
      });
    }
  }

  function toggleChecked(e) {
    handleChange((prevState) => {
      return { ...prevState, [e.target.name]: e.target.checked };
    });
  }

  function sortRideTypes(rideTypes) {
    return rideTypes.sort((a, b) => RIDE_TYPES.indexOf(a) - RIDE_TYPES.indexOf(b));
  }

  return (
    <Box sx={{ display: 'flex', columnGap: '8px', justifyContent: justifyContent }}>
      {sortRideTypes(rideTypes).map((t) =>
        readOnly ? (
          <RouteTypeIcon key={t} type={t} />
        ) : (
          <Checkbox
            key={t}
            name={t}
            checked={Boolean(checkedState[t])}
            onChange={toggleChecked}
            inputProps={{ 'aria-label': 'controlled' }}
            icon={<RouteTypeIcon key={t} type={t} checked={false} />}
            checkedIcon={<RouteTypeIcon key={t} type={t} checked={true} />}
          />
        )
      )}
    </Box>
  );
};

export default RideTypesBar;
