import React, { useRef, useState } from 'react';
import { useData } from '../../contexts/DataContext';
import RouteForm from './RouteForm';

const CreateRouteForm = () => {
  const { createRoute } = useData();

  function handleSubmit(formObj) {
    createRoute(formObj);
  }

  return <RouteForm handleSubmit={handleSubmit} submitText={'Создать'} />;
};

export default CreateRouteForm;
