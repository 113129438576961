import { Box } from '@mui/material';
import React from 'react';

const NoRecordsMessage = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      Пока здесь пусто...
    </Box>
  );
};

export default NoRecordsMessage;
