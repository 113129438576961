import React from 'react';
import { Box } from '@mui/material';

const RouteCardDottedLine = ({ lineStart, lineEnd }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      {lineStart}
      {lineEnd ? (
        <>
          <Box
            sx={{
              flexGrow: 1,
              margin: 'auto',
              mt: '4px',
              mb: '4px',
              borderLeft: 'dotted 2px grey',
            }}
          ></Box>
          {lineEnd}
        </>
      ) : null}
    </Box>
  );
};

export default RouteCardDottedLine;
