import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Global } from '@emotion/react';
import Box from '@mui/material/Box';
import { SwipeableDrawer, Typography } from '@mui/material';
import { useData } from '../../contexts/DataContext';
import RoutesCardMini from '../routes/RoutesCardMini';
import { STYLES_BORDER_RADIUS } from '../../helpers/styles';
import Puller from '../utils/Puller';
import { useAuth } from '../../contexts/AuthContext';
import { URL_LOGIN, URL_ROUTES_CREATE } from '../../helpers/consts';
import { useStatus } from '../../contexts/StatusContext';
import Spinner from '../utils/Spinner';
import SubmitButton from '../utils/SubmitButton';
import { useHistory } from 'react-router-dom';

const SendRequestSelectForm = ({ selectedRoute, handleClose }) => {
  const { own_routes, getOwnRoutesData, sendRequestToShareRoute } = useData();
  const { user } = useAuth();
  const { loading } = useStatus();
  const history = useHistory();

  useEffect(() => {
    getOwnRoutesData();
  }, []);

  function handleSendRequestClick(offeredRouteId) {
    sendRequestToShareRoute(offeredRouteId, selectedRoute.id);
    handleClose();
  }

  return (
    <Box>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            backgroundColor: 'transparent',
          },
        }}
      />
      <SwipeableDrawer
        anchor="bottom"
        open={Boolean(selectedRoute)}
        onOpen={() => null}
        onClose={handleClose}
      >
        <Box
          sx={{
            padding: '0 16px',
            backgroundColor: 'whitesmoke',
            borderTopLeftRadius: STYLES_BORDER_RADIUS,
            borderTopRightRadius: STYLES_BORDER_RADIUS,
            height: '80vh',
            overflow: 'hidden',
          }}
        >
          <Puller onClick={handleClose} />
          {user ? (
            own_routes.length > 0 ? (
              <>
                <Typography align="center" component="div" sx={{ fontWeight: 700, mb: 3 }}>
                  Выберите свой маршрут
                </Typography>
                <Box sx={{ height: '65vh', overflow: 'scroll' }}>
                  {own_routes.map((item) => (
                    <RoutesCardMini
                      key={item.id}
                      item={item}
                      handleSubmit={handleSendRequestClick}
                    />
                  ))}
                </Box>
              </>
            ) : (
              <>
                <Typography align="center" component="div" sx={{ fontWeight: 700, mb: 3 }}>
                  У вас ещё нет своих маршрутов
                </Typography>
                <SubmitButton
                  text={'Cоздать сейчас'}
                  handleClick={() => history.push(URL_ROUTES_CREATE)}
                />
              </>
            )
          ) : (
            <Box
              sx={{
                height: '70%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Link to={URL_LOGIN}>
                <Typography component="p" color="primary">
                  Чтобы продолжить, выполните Вход
                </Typography>
              </Link>
            </Box>
          )}
          {loading && <Spinner />}
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};

export default SendRequestSelectForm;
