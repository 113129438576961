import React from 'react';
import EditRouteForm from '../../components/routes/EditRouteForm';
import FooterLessLayout from '../../layouts/FooterLessLayout';

const EditRoutePage = () => {
  return (
    <FooterLessLayout backgroundColor="transparent">
      <EditRouteForm />
    </FooterLessLayout>
  );
};

export default EditRoutePage;
