import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import SubmitButton from '../utils/SubmitButton';
import { useAuth } from '../../contexts/AuthContext';
import Password from '../../assets/password.svg';
import { Stack } from '@mui/material';
import { URL_LOGIN } from '../../helpers/consts';
import { useStatus } from '../../contexts/StatusContext';
import SpinnerCover from '../utils/SpinnerCover';

const ForgotPasswordForm = () => {
  const email = useRef('');
  const { forgotPassword } = useAuth();
  const { loading } = useStatus();

  function handleSubmit(e) {
    e.preventDefault();
    forgotPassword(email.current.value);
    email.current.value = '';
  }

  return (
    <>
      <Container>
        <Stack
          direction={'column'}
          component={'form'}
          spacing={2}
          display={'flex'}
          alignItems={'center'}
          mt={6}
        >
          <Typography component="h1" variant="h6" sx={{ marginBottom: '20px' }}>
            Забыли пароль?
          </Typography>
          <Box>
            <Avatar
              alt="email icon illustration"
              src={Password}
              variant="square"
              sx={{ width: 200, height: 200, marginBottom: '20px' }}
            />
            {/* <img src={emailIcon} alt="email icon" width={'100px'} height={'100px'} /> */}
          </Box>
          <TextField
            fullWidth
            name="email"
            required
            id="email"
            label="Электронная почта"
            inputRef={email}
            autoFocus
          />
          <Box sx={{ alignSelf: 'flex-end' }}>
            <Typography variant="string" color="text.primary">
              Вспомнили Пароль?
            </Typography>
            <Link to={URL_LOGIN}>
              <Typography variant="string" color="primary" ml={1}>
                Войти
              </Typography>
            </Link>
          </Box>

          <SubmitButton text={'Отправить'} handleClick={handleSubmit} />
        </Stack>
      </Container>
      {loading && <SpinnerCover />}
    </>
  );
};

export default ForgotPasswordForm;
