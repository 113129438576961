import React from 'react';
import { Checkbox, Stack, Typography } from '@mui/material';

const daysOfWeek = {
  1: { id: 1, name: 'monday', label: 'Пн' },
  2: { id: 2, name: 'tuesday', label: 'Вт' },
  3: { id: 3, name: 'wednesday', label: 'Ср' },
  4: { id: 4, name: 'thursday', label: 'Чт' },
  5: { id: 5, name: 'friday', label: 'Пт' },
  6: { id: 6, name: 'saturday', label: 'Сб' },
  7: { id: 7, name: 'sunday', label: 'Вс' },
};

const DayOfWeekBar = ({ readOnly = true, checkedState, handleChange }) => {
  function toggleChecked(e) {
    handleChange((prevState) => {
      return { ...prevState, [e.target.name]: e.target.checked };
    });
  }

  const DayOfWeek = ({ item }) =>
    readOnly ? (
      checkedState.includes(item.id) ? (
        <Typography fontWeight="bold" fontSize="0.75em" color="primary">
          {item.label}
        </Typography>
      ) : (
        <Typography fontSize="0.75em" color="text.disabled">
          {item.label}
        </Typography>
      )
    ) : (
      <Checkbox
        sx={{ pl: '6px', pr: '6px' }}
        name={item.id.toString()}
        checked={checkedState[item.id]}
        onChange={toggleChecked}
        icon={<Typography fontSize="small">{item.label}</Typography>}
        checkedIcon={
          <Typography fontWeight="bold" fontSize="small">
            {item.label}
          </Typography>
        }
      />
    );

  return readOnly ? (
    <Stack
      direction="row"
      justifyContent="flex-start"
      spacing={1}
      // divider={<Divider orientation="vertical" flexItem />}
      // border="solid 1px rgba(0, 0, 0, 0.23)"
      // borderRadius="4px"
      // sx={{ border: 'solid 1px rgba(0, 0, 0, 0.23)', borderRadius: '4px' }}
    >
      {Object.entries(daysOfWeek).map(([key, val]) => (
        <DayOfWeek key={key} item={val} />
      ))}
    </Stack>
  ) : (
    <Stack
      direction="row"
      justifyContent="space-evenly"
      // divider={<Divider orientation="vertical" flexItem />}
      // border="solid 1px rgba(0, 0, 0, 0.23)"
      // borderRadius="4px"
      // sx={{ border: 'solid 1px rgba(0, 0, 0, 0.23)', borderRadius: '4px' }}
    >
      {Object.entries(daysOfWeek).map(([key, val]) => (
        <DayOfWeek key={key} item={val} />
      ))}
    </Stack>
  );
};

export default DayOfWeekBar;
