import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import React from 'react';
import { Button } from '@mui/material';

// const puller = styled(Box)(({ theme }) => ({
//   width: 30,
//   height: 6,
//   backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
//   borderRadius: 3,
//   position: 'absolute',
//   top: 8,
//   left: 'calc(50% - 15px)',
// }));

const Puller = ({ onClick, backgroundColor = grey[300] }) => {
  return (
    <Button
      fullWidth
      onClick={onClick}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '8px 0' }}
    >
      <Box
        sx={{
          width: 30,
          height: 6,
          backgroundColor: backgroundColor,
          borderRadius: 3,
        }}
      ></Box>
    </Button>
  );
};

export default Puller;
