import axios from 'axios';
import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import {
  API_AUTH_3RD_PARTY_PROVIDER_URL,
  API_AUTH_3RD_PARTY_VERIFY_URL,
  API_AUTH_CONFIRM_EMAIL,
  API_AUTH_LOGIN,
  API_AUTH_REGISTRATION,
  API_AUTH_RENEW,
  API_AUTH_RESET_PASSWORD,
  API_PROFILES,
  GET_PROVIDER_DATA,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REGISTER_ERROR,
  STATUS_SUCCESS,
  UPDATE_PROFILE_DATA,
} from '../helpers/consts';
import { compileMessages, supervise_rq } from '../helpers/utils';
import useLocalStorage from '../hooks/useLocalStorage';
import { useStatus } from './StatusContext';

const ACCESS_TOKEN_EXPIRES_IN = 1800000; // ! 30 minutes in milliseconds.
const RENEWAL_TOKEN_EXPIRES_IN = 2592000000; // ! 30 days in milliseconds.
const authContext = createContext();

export const useAuth = () => {
  return useContext(authContext);
};

const INIT_STATE = {
  user: null,
  providers: {},
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };
    case LOGIN_ERROR:
      return { ...state };
    case LOGOUT_SUCCESS:
      return INIT_STATE;
    case GET_PROVIDER_DATA:
      return {
        ...state,
        providers: { ...state.providers, ...action.payload },
      };
    case UPDATE_PROFILE_DATA:
      return {
        ...state,
        user: { ...state.user, user_profile: action.payload },
      };

    default:
      return state;
  }
};

const AuthContextProvider = ({ children }) => {
  const { setLoading, setInfo, setError } = useStatus();
  const [state, setLocalState] = useLocalStorage('auth_state', INIT_STATE);
  // const [state, dispatch] = useReducer(reducer, localState);
  const [authLoading, setAuthLoading] = useState(true);
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [forgotPasswordRequested, setForgotPasswordRequested] = useState(false);

  useEffect(() => {
    initAuth();
  }, []);

  function dispatch(action) {
    setLocalState((prevState) => reducer(prevState, action));
  }

  function dispatchFreshState() {
    setLocalState(INIT_STATE);
  }

  // ! HELPER FUNCTIONS
  function isExpiredToken(tokens) {
    if (!tokens) {
      dispatchFreshState();
      return true;
    }
    if (!tokens.issued_at) return true;

    return tokens.issued_at + ACCESS_TOKEN_EXPIRES_IN <= Date.now();
    // return tokens.issued_at + 30000 <= Date.now();
  }

  function setUserData({ access_token, renewal_token, user_id, user_email, user_profile }) {
    const userData = {
      tokens: {
        access_token,
        renewal_token,
        issued_at: Date.now(),
      },
      user_id,
      user_email,
      user_profile: user_profile,
    };
    dispatch({
      type: LOGIN_SUCCESS,
      payload: userData,
    });
  }

  // ! BACKGROUND AUTHENTICATION
  // here we try to authenticate user in background as soon as page loads
  // if tokens are expired we call renewTokens, else we update user state,
  // so requests can be authenticated with valid token
  async function initAuth() {
    if (state.user) {
      const isExpired = isExpiredToken(state.user?.tokens);
      if (isExpired) {
        await renewTokens();
      } else {
        setAuthLoading(false);
      }
    } else {
      setAuthLoading(false);
    }
  }

  // ! REGISTRATION
  // registration handled here. when registered, email will be sent with a link to confrim email address,
  // and only after user can sign, hence here we just display a message about request status
  async function register(userForm) {
    setLoading(true);
    const body = {
      user: {
        email: userForm.email,
        password: userForm.password,
        password_confirmation: userForm.repeat_password,
      },
    };
    const resp = await supervise_rq(() => axios.post(API_AUTH_REGISTRATION, body));
    if (resp.status === STATUS_SUCCESS) {
      setInfo(resp.data.data.message);
    } else {
      setError(resp.data.error.message);
    }
    setLoading(false);
  }

  // ! EMAIL CONFIRMATION / VERIFICATION
  // after registration, email will be sent with a link to confrim email address,
  // and this confirmation process handled here
  // when user clicks on link he/she lands on our client page, where we extract the confirmation token
  // and pass this token to server to verify
  async function confirmEmail(token) {
    setLoading(true);
    const resp = await supervise_rq(() => axios.get(`${API_AUTH_CONFIRM_EMAIL}/${token}`));
    if (resp.status === STATUS_SUCCESS) {
      setEmailConfirmed(true);
      setInfo(resp.data.data.message);
    } else {
      setEmailConfirmed(false);
      setError(resp.data.error.message);
    }
    setLoading(false);
  }

  // ! LOGIN
  // login handled here. when loged in, an access_token will be provided,
  // which is used to authorize private requests
  // ! access_token validity 30 MINUTES
  // ! renewal_token validity 30 DAYS
  async function login({ email, password }) {
    setLoading(true);
    const body = {
      user: {
        email,
        password,
      },
    };
    const resp = await supervise_rq(() => axios.post(API_AUTH_LOGIN, body));

    if (resp.status === STATUS_SUCCESS) {
      setUserData(resp.data.data);
      setInfo('Login success');
    } else {
      setError(resp.data.error.message);
    }
    setLoading(false);
  }

  // ! LOGOUT
  // logout handled here. when logged out all user private data must me erased
  async function logout() {
    setLoading(true);
    const headers = await attachAuth();
    const resp = await supervise_rq(() => axios.delete(API_AUTH_LOGIN, { headers }));

    if (resp.status === STATUS_SUCCESS) {
      dispatch({ type: LOGOUT_SUCCESS });
      setInfo('Logout success');
    } else {
      setError(resp.data.error.message);
    }
    setLoading(false);
  }

  // ! Get Provider's Auth Redirect Url
  async function getProvidersData(provider) {
    setLoading(true);
    const resp = await supervise_rq(() => axios.get(API_AUTH_3RD_PARTY_PROVIDER_URL(provider)));

    if (resp.status === STATUS_SUCCESS) {
      dispatch({
        type: GET_PROVIDER_DATA,
        payload: { [provider]: resp.data.data },
      });
    } else {
      setError(resp.data.error.message);
    }
    setLoading(false);
  }

  // ! Login via 3rd Party Providers (Google)
  async function loginViaProvider(provider, search) {
    setLoading(true);
    const body = {
      provider,
      session_params: state.providers[provider].session_params,
      code: search.get('code'),
    };
    const resp = await supervise_rq(() =>
      axios.post(API_AUTH_3RD_PARTY_VERIFY_URL(provider), body)
    );

    if (resp.status === STATUS_SUCCESS) {
      setUserData(resp.data.data);
    } else {
      setError(resp.data.error.message);
    }
    setLoading(false);
  }

  // ! RENEWAL OF TOKENS
  // using renewal_token new access_token requested to replace expired one
  // if renewal_token has also been expired or invalidated, then tokens are deleted and user must log in if required
  async function renewTokens() {
    if (!state.user.tokens) return;

    setAuthLoading(true);

    const headers = makeAuthHeader({}, state.user.tokens.renewal_token);
    const body = {};
    const resp = await supervise_rq(() => axios.post(API_AUTH_RENEW, body, { headers }));

    if (resp.status === STATUS_SUCCESS) {
      setUserData(resp.data.data);
      setAuthLoading(false);
    } else {
      dispatch({ type: LOGOUT_SUCCESS });
      // setError(resp.data.error.message);
    }
  }

  // ! FORGOT PASSWORD
  // if user forgot a password he/she can request for a password reset link by providing an email that is used to register
  // if an email exists the reset password link will be sent to that email
  // user can open a link and update the password
  async function forgotPassword(email) {
    setLoading(true);
    const body = { user: { email } };
    const resp = await supervise_rq(() => axios.post(API_AUTH_RESET_PASSWORD, body));

    if (resp.status === STATUS_SUCCESS) {
      setForgotPasswordRequested(true);
      setInfo(resp.data.data.message);
    } else {
      setForgotPasswordRequested(false);
      // setError(resp.data);
    }

    setLoading(false);
  }

  // ! RESET PASSWORD
  // when user received an email with reset passowrd link, he/she can open a link and enter new password
  // if a token in a link is valid then the passowrd will be updated
  async function resetPassword(formData) {
    setLoading(true);
    const body = {
      user: {
        password: formData.password,
        password_confirmation: formData.repeat_password,
      },
    };
    const resp = await supervise_rq(() =>
      axios.patch(`${API_AUTH_RESET_PASSWORD}/${formData.token}`, body)
    );

    if (resp.status === STATUS_SUCCESS) {
      setInfo(resp.data.data.message);
    } else {
      setError(resp.data.error.message);
    }
    setLoading(false);
  }

  // ! ATTACH AUTHORIZATION
  // here we attach Authorization with the access_token into Request Headers
  async function attachAuth(headers = {}) {
    if (!state.user) return {};
    const isExpired = isExpiredToken(state.user.tokens);
    if (isExpired) {
      // return;
      await renewTokens();
    }
    return makeAuthHeader(headers, state.user.tokens.access_token);
  }

  function makeAuthHeader(headers = {}, token) {
    headers['Authorization'] = token;
    return { ...headers };
  }

  // ! USER PROFILE UPDATE
  async function updateUserProfile(profileForm) {
    const headers = await attachAuth();
    setLoading(true);
    const body = {
      profile: {
        id: profileForm.id,
        first_name: profileForm.firstName,
        last_name: profileForm.lastName,
        phone_number: profileForm.phone,
        dob: profileForm.dob,
      },
    };
    const resp = await supervise_rq(() => axios.post(API_PROFILES, body, { headers }));
    if (resp.status === STATUS_SUCCESS) {
      dispatch({ type: UPDATE_PROFILE_DATA, payload: resp.data.data });
      setInfo('Профиль успешно изменён');
    } else {
      const errorMessages = compileMessages(resp.data.errors);
      setError(errorMessages);
    }
    setLoading(false);
  }

  // ! EXPORTED STATE
  // group all values that we wish to share/provide in context
  const values = {
    authLoading: authLoading,
    user: state.user,
    providers: state.providers,
    email_confirmed: emailConfirmed,
    forgot_password_requested: forgotPasswordRequested,

    register,
    confirmEmail,
    getProvidersData,
    loginViaProvider,
    login,
    logout,
    forgotPassword,
    resetPassword,
    updateUserProfile,

    attachAuth,
  };

  return <authContext.Provider value={values}>{children}</authContext.Provider>;
};

export default AuthContextProvider;
