import React, { useRef } from 'react';
import ProfileEdit from '../../components/profile/ProfileEdit';
import LayoutContent from '../../layouts/LayoutContent';
import LayoutFooter from '../../layouts/LayoutFooter';
import LayoutHeader from '../../layouts/LayoutHeader';
import LayoutWrapper from '../../layouts/LayoutWrapper';

const MenuPage = () => {
  const contentRef = useRef();

  return (
    <LayoutWrapper>
      <LayoutHeader title={'Профиль'} goBack={true} triggerTarget={contentRef.current} />
      <LayoutContent forwardRef={contentRef}>
        <ProfileEdit />
      </LayoutContent>
      <LayoutFooter />
    </LayoutWrapper>
  );
};

export default MenuPage;
