import axios from 'axios';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router';
import {
  GET_ROUTES,
  API_ROUTES,
  STATUS_SUCCESS,
  API_SHARE_REQUESTS,
  API_SENT_REQUESTS,
  GET_SENT_REQUESTS,
  API_RECEIVED_REQUESTS,
  GET_RECEIVED_REQUESTS,
  API_OWN_ROUTES,
  GET_OWN_ROUTES,
  GET_CHAT_ROOMS,
  API_CHAT_ROOMS,
  API_USERS,
  MESSAGE_RECORDS_LOADED,
  MESSAGE_LOADING_FAILED,
  GET_CHAT_MESSAGES,
  API_CHAT_MESSAGES,
  URL_OWN_ROUTES,
  GET_ROUTE_DETAILS,
  URL_SENT_REQUESTS,
} from '../helpers/consts';
import { supervise_rq } from '../helpers/utils';
import { useAuth } from './AuthContext';
import { useStatus } from './StatusContext';

const dataContext = createContext();

export const useData = () => {
  return useContext(dataContext);
};

const INIT_STATE = {
  routes: {},
  own_routes: [],
  favorites: [],
  notifications: [],
  sent_requests: [],
  received_requests: [],
  chat_rooms: null,
  messages: [],
  route_details: null,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ROUTES:
      return { ...state, routes: action.payload };
    case GET_OWN_ROUTES:
      return { ...state, own_routes: action.payload };
    case GET_SENT_REQUESTS:
      return { ...state, sent_requests: action.payload };
    case GET_RECEIVED_REQUESTS:
      return { ...state, received_requests: action.payload };
    case GET_CHAT_ROOMS:
      return { ...state, chat_rooms: action.payload };
    case GET_CHAT_MESSAGES:
      return { ...state, messages: [...state.messages, ...action.payload] };
    case GET_ROUTE_DETAILS:
      return { ...state, route_details: action.payload };
    default:
      return state;
  }
};

const DataContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INIT_STATE);
  const { setLoading, setInfo, setError } = useStatus();
  const { attachAuth, user } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (user) {
      getOwnRoutesData();
      getSentRequestsData();
      getReceivedRequestsData();
      getChatRoomsData();
    }
  }, [user]);

  // ! CREATE ROUTE
  // here we post data to API and create a new Route
  async function createRoute({
    title,
    from_location,
    to_location,
    departure_time,
    arrival_time,
    ride_types,
    dow,
    money,
    valid_from,
    valid_till,
    is_active,
    description,
  }) {
    const body = {
      route: {
        title,
        from_location,
        to_location,
        departure_time,
        arrival_time,
        ride_types,
        dow,
        money,
        valid_from,
        valid_till,
        is_active,
        description,
      },
    };

    setLoading(true);

    const headers = await attachAuth();
    const resp = await supervise_rq(() => axios.post(API_ROUTES, body, { headers }));

    if (resp.status === STATUS_SUCCESS) {
      setInfo('Маршрут успешно создан');
      history.push(URL_OWN_ROUTES);
    } else {
      const [[key, value], ...rest] = Object.entries(resp.data.errors);
      setError(`${key} - ${value}`);
    }

    setLoading(false);
  }

  // ! func to get routes data
  async function getRoutesData() {
    const search = new URLSearchParams(history.location.search);
    history.push(`${history.location.pathname}?${search.toString()}`);
    setLoading(true);
    const resp = await supervise_rq(() => axios(`${API_ROUTES}/${window.location.search}`));
    if (resp.status === STATUS_SUCCESS) {
      dispatch({ type: GET_ROUTES, payload: resp.data });
    } else {
      setError(MESSAGE_LOADING_FAILED);
    }
    setLoading(false);
  }

  // ! func to get next page routes data for infinite scroll
  // difference is that we do not delete existing
  async function getNextPageRoutesData(page) {
    const search = new URLSearchParams(history.location.search);
    search.set('page', page);
    // history.push(`${history.location.pathname}?${search.toString()}`);

    setLoading(true);
    const resp = await supervise_rq(() => axios(`${API_ROUTES}/?${search.toString()}`));
    if (resp.status === STATUS_SUCCESS) {
      const payload = {
        ...resp.data,
        data: [...state.routes.data, ...resp.data.data],
      };
      dispatch({ type: GET_ROUTES, payload });
    } else {
      setError(MESSAGE_LOADING_FAILED);
    }
    setLoading(false);
  }

  // ! here we request routes that belong to authenticated user
  async function getOwnRoutesData() {
    setLoading(true);
    const headers = await attachAuth();
    const resp = await supervise_rq(() => axios(API_OWN_ROUTES, { headers }));
    if (resp.status === STATUS_SUCCESS) {
      dispatch({ type: GET_OWN_ROUTES, payload: resp.data.data });
    } else {
      setError(resp.data.error.message);
    }
    setLoading(false);
  }

  // ! here we delete route
  async function deleteOwnRoute(id) {
    setLoading(true);
    const headers = await attachAuth();

    const resp = await supervise_rq(() => axios.delete(`${API_ROUTES}/${id}`, { headers }));
    if (resp.status === STATUS_SUCCESS) {
      setInfo('Маршрут успешно удалён');
    } else {
      setError(resp.data.error.message);
    }
    setLoading(false);
  }

  // func to send request to share routes
  async function sendRequestToShareRoute(sender_route_id, receiver_route_id) {
    setLoading(true);
    const body = {
      request: {
        status: 'pending',
        sender_route_id: sender_route_id,
        receiver_route_id: receiver_route_id,
      },
    };
    const headers = await attachAuth();
    const resp = await supervise_rq(() => axios.post(API_SHARE_REQUESTS, body, { headers }));

    if (resp.status === STATUS_SUCCESS) {
      setInfo('Запрос успешно отправлен');
      history.push(URL_SENT_REQUESTS);
    } else {
      setError(resp.data.data.message);
    }
    setLoading(false);
  }

  async function getSentRequestsData() {
    setLoading(true);
    const headers = await attachAuth();
    const resp = await supervise_rq(() => axios(API_SENT_REQUESTS, { headers }));
    if (resp.status === STATUS_SUCCESS) {
      dispatch({ type: GET_SENT_REQUESTS, payload: resp.data.data });
    }
    setLoading(false);
  }

  async function getReceivedRequestsData() {
    setLoading(true);
    const headers = await attachAuth();
    const resp = await supervise_rq(() => axios(API_RECEIVED_REQUESTS, { headers }));
    if (resp.status === STATUS_SUCCESS) {
      dispatch({ type: GET_RECEIVED_REQUESTS, payload: resp.data.data });
    } else {
      setError(resp.data.error.message);
    }
    setLoading(false);
  }

  // func to revoke sent requests to share routes
  async function revokeSentRequest(requestId) {
    setLoading(true);
    const headers = await attachAuth();
    const resp = await supervise_rq(() =>
      axios.delete(`${API_SHARE_REQUESTS}/${requestId}`, { headers })
    );
    if (resp.status === STATUS_SUCCESS) {
      const arrWithoutSentRequest = state.sent_requests.filter((item) => item.id !== requestId);
      dispatch({ type: GET_SENT_REQUESTS, payload: arrWithoutSentRequest });
      setInfo('Запрос отменён');
    } else {
      setError(resp.data.error.message);
    }
    setLoading(false);
  }

  async function acceptReceivedRequest(requestId) {
    setLoading(true);
    const receivedRequest = state.received_requests.find((item) => item.id === requestId);
    receivedRequest.status = 'accepted';
    const headers = await attachAuth();
    const resp = await supervise_rq(() =>
      axios.patch(
        `${API_SHARE_REQUESTS}/${requestId}`,
        { request: { status: 'accepted' } },
        { headers }
      )
    );
    if (resp.status === STATUS_SUCCESS) {
      const modifedArrOfRequests = state.received_requests.map((item) => {
        if (item.id === requestId) {
          return receivedRequest;
        }
        return item;
      });
      dispatch({ type: GET_RECEIVED_REQUESTS, payload: modifedArrOfRequests });
      setInfo('Запрос принят');
    } else {
      setError(resp.data.error.message);
    }
    setLoading(false);
  }

  async function rejectReceivedRequest(requestId) {
    setLoading(true);
    const receivedRequest = state.received_requests.find((item) => item.id === requestId);
    receivedRequest.status = 'rejected';
    const headers = await attachAuth();
    const resp = await supervise_rq(() =>
      axios.patch(
        `${API_SHARE_REQUESTS}/${requestId}`,
        { request: { status: 'rejected' } },
        { headers }
      )
    );
    if (resp.status === STATUS_SUCCESS) {
      const modifedArrOfRequests = state.received_requests.map((item) => {
        if (item.id === requestId) {
          return receivedRequest;
        }
        return item;
      });
      dispatch({ type: GET_RECEIVED_REQUESTS, payload: modifedArrOfRequests });
      setInfo('Запрос отклонён');
    } else {
      setError(resp.data.error.message);
    }
    setLoading(false);
  }

  // ! func to get chat rooms data
  async function getChatRoomsData() {
    // const search = new URLSearchParams(history.location.search);
    // history.push(`${history.location.pathname}?${search.toString()}`);
    setLoading(true);
    const headers = await attachAuth();
    const resp = await supervise_rq(() =>
      axios(`${API_CHAT_ROOMS}/${window.location.search}`, { headers })
    );

    if (resp.status === STATUS_SUCCESS) {
      dispatch({ type: GET_CHAT_ROOMS, payload: resp.data });
    } else {
      setError(resp.data.error.message);
    }
    setLoading(false);
  }

  function getOldMessages(resp) {
    dispatch({ type: GET_CHAT_MESSAGES, payload: [...resp.messages] });
  }

  function addNewMessage(payload) {
    if (state.messages.length === 0) {
      dispatch({
        type: GET_CHAT_MESSAGES,
        payload: [payload.body],
      });
    } else {
      dispatch({
        type: GET_CHAT_MESSAGES,
        payload: [...state.messages, payload.body],
      });
    }
  }

  async function getRouteDetails(id) {
    setLoading(true);
    const headers = await attachAuth();
    const resp = await supervise_rq(() => axios(`${API_ROUTES}/${id}`, { headers }));

    if (resp.status === STATUS_SUCCESS) {
      dispatch({ type: GET_ROUTE_DETAILS, payload: resp.data.data });
    } else {
      setError(resp.data.error.message);
    }
    setLoading(false);
  }

  async function editRoute({
    title,
    from_location,
    to_location,
    departure_time,
    arrival_time,
    ride_types,
    dow,
    money,
    valid_from,
    valid_till,
    is_active,
    description,
    id,
    user_id,
  }) {
    const body = {
      route: {
        title,
        from_location,
        to_location,
        departure_time,
        arrival_time,
        ride_types,
        dow,
        money,
        valid_from,
        valid_till,
        is_active,
        description,
      },
    };
    setLoading(true);

    const headers = await attachAuth();
    const resp = await supervise_rq(() => axios.patch(`${API_ROUTES}/${id}`, body, { headers }));

    if (resp.status === STATUS_SUCCESS) {
      setInfo('Маршрут упешно изменён');
      history.push(URL_OWN_ROUTES);
    } else {
      const [[key, value], ...rest] = Object.entries(resp.data.errors);
      setError(`${key} - ${value}`);
    }

    setLoading(false);
  }

  // ! EXPORTED STATE
  // group all values that we wish to share/provide in context
  const values = {
    routes: state.routes,
    own_routes: state.own_routes,
    sent_requests: state.sent_requests,
    received_requests: state.received_requests,
    chat_rooms: state.chat_rooms,
    messages: state.messages,
    route_details: state.route_details,
    dispatch,

    createRoute,
    editRoute,
    deleteOwnRoute,
    getRoutesData,
    getNextPageRoutesData,
    getOwnRoutesData,
    sendRequestToShareRoute,
    getSentRequestsData,
    getReceivedRequestsData,
    revokeSentRequest,
    acceptReceivedRequest,
    rejectReceivedRequest,
    getChatRoomsData,
    getOldMessages,
    addNewMessage,
    getRouteDetails,
  };

  return <dataContext.Provider value={values}>{children}</dataContext.Provider>;
};

export default DataContextProvider;
