import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Fab, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useData } from '../../contexts/DataContext';
import { useStatus } from '../../contexts/StatusContext';
import SendRequestSelectForm from '../requests/SendRequestSelectForm';
import NoRecordsMessage from '../utils/NoRecordsMessage';
import Spinner from '../utils/Spinner';
import RoutesCard from './RoutesCard';
import { URL_ROUTES_CREATE } from '../../helpers/consts';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const RoutesList = () => {
  const { loading } = useStatus();
  const {
    getRoutesData,
    getNextPageRoutesData,
    routes,
    sent_requests,
    received_requests,
    own_routes,
  } = useData();
  const { user } = useAuth();
  const { data: list, has_next_page, current_page } = routes;

  const [expandedRoute, setExpandedRoute] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState(null);

  useEffect(() => {
    getRoutesData();
  }, []);

  // ! for infinite scroll
  const observer = useRef();
  const lastListElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && has_next_page) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, has_next_page]
  );

  function loadMoreData() {
    // const search = new URLSearchParams(history.location.search);
    // search.set('page', current_page + 1);
    // history.push(`${history.location.pathname}?${search.toString()}`);
    getNextPageRoutesData(current_page + 1);
  }

  function handleExpand(route) {
    setExpandedRoute((prevState) => {
      if (prevState === null) return route;
      else if (route.id === prevState.id) return null;
      else return route;
    });
  }

  function handleShareOpen(route) {
    setSelectedRoute(route);
  }

  function handleShareClose() {
    setSelectedRoute(null);
  }

  const isLiked = (item) =>
    sent_requests.some((elem) => elem.receiver_route.id === item.id) ||
    received_requests.some((elem) => elem.sender_route.id === item.id);

  return (
    <Stack spacing={2} marginBottom="16px">
      {list && list.length !== 0 ? (
        <>
          {list.map((item) => {
            if (own_routes.some((el) => el.id === item.id)) {
              return null;
            } else {
              return (
                <RoutesCard
                  key={item.id}
                  item={item}
                  liked={isLiked(item)}
                  handleExpandClick={handleExpand}
                  expandedRoute={expandedRoute}
                  handleShareClick={handleShareOpen}
                />
              );
            }
          })}
          <Box ref={lastListElementRef}></Box>
        </>
      ) : (
        <NoRecordsMessage />
      )}
      {selectedRoute && (
        <SendRequestSelectForm selectedRoute={selectedRoute} handleClose={handleShareClose} />
      )}
      {loading && <Spinner />}
      {(!user || own_routes.length === 0) && (
        <Link to={URL_ROUTES_CREATE}>
          <Fab color="primary" sx={{ position: 'fixed', bottom: '76px', right: '26px' }}>
            <AddIcon />
          </Fab>
        </Link>
      )}
    </Stack>
  );
};

export default RoutesList;
