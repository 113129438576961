import React from 'react';
import ResetPasswordForm from '../../components/auth/ResetPasswordForm';
import FooterLessLayout from '../../layouts/FooterLessLayout';

const ResetPasswordPage = () => {
  return (
    <FooterLessLayout>
      <ResetPasswordForm />
    </FooterLessLayout>
  );
};

export default ResetPasswordPage;
