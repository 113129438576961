import React, { useRef } from 'react';
import OwnRoutesList from '../../components/routes/OwnRoutesList';
import LayoutContent from '../../layouts/LayoutContent';
import LayoutFooter from '../../layouts/LayoutFooter';
import LayoutHeader from '../../layouts/LayoutHeader';
import LayoutWrapper from '../../layouts/LayoutWrapper';

const OwnRoutesPage = () => {
  const contentRef = useRef();

  return (
    <LayoutWrapper>
      <LayoutHeader title={'Мои Маршруты'} goBack={true} triggerTarget={contentRef.current} />
      <LayoutContent forwardRef={contentRef}>
        <OwnRoutesList />
      </LayoutContent>
      <LayoutFooter />
    </LayoutWrapper>
  );
};

export default OwnRoutesPage;
