import React from 'react';
import CreateRouteForm from '../../components/routes/CreateRouteForm';
import FooterLessLayout from '../../layouts/FooterLessLayout';

const CreateRoutePage = () => {
  return (
    <FooterLessLayout backgroundColor="transparent">
      <CreateRouteForm />
    </FooterLessLayout>
  );
};

export default CreateRoutePage;
