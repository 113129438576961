import React, { useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { Button, Divider, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { URL_HOME, URL_REGISTRATION, URL_FORGOT_PASSWORD } from '../../helpers/consts';
import { useHistory, useLocation } from 'react-router';
import IconGoogleSVG from '../../assets/icon_google.svg';
import SubmitButton from '../utils/SubmitButton';
import { useStatus } from '../../contexts/StatusContext';
import { useAuth } from '../../contexts/AuthContext';
import SpinnerCover from '../utils/SpinnerCover';
import rounded_icon from '../../assets/rounded_icon.ico';

const LoginForm = () => {
  const history = useHistory();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: URL_HOME } };
  const { provider } = useParams();
  const formRef = useRef();
  const { user, providers, getProvidersData, loginViaProvider, login } = useAuth();
  const { loading } = useStatus();

  useEffect(() => {
    if (!providers.google) getProvidersData('google');
  }, []);

  useEffect(() => {
    if (provider) {
      const search = new URLSearchParams(history.location.search);
      loginViaProvider(provider, search);
    }
  }, []);

  useEffect(() => {
    if (user) history.replace(from);
  }, [user]);

  function handleSubmit(e) {
    e.preventDefault();

    const {
      current: { email, password },
    } = formRef;

    const userForm = {
      email: email.value,
      password: password.value,
    };
    login(userForm);
  }

  return (
    <>
      <Container>
        <Stack
          ref={formRef}
          component={'form'}
          direction={'column'}
          spacing={2}
          display={'flex'}
          alignItems={'center'}
          mt={6}
        >
          <img src={rounded_icon} alt="sharit_logo" width="45px" height="45px" />
          <Typography component="h1" variant="h5">
            Вход
          </Typography>

          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Электронная почта"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Пароль"
            type="password"
            id="password"
            autoComplete="current-password"
          />

          <SubmitButton text={'Войти'} handleClick={handleSubmit} />
          <Box sx={{ alignSelf: 'flex-end' }}>
            <Typography variant="string" color="text.primary">
              Нет аккаунта?
            </Typography>
            <Link to={URL_REGISTRATION}>
              <Typography variant="string" color="primary" ml={1}>
                Создать
              </Typography>
            </Link>
          </Box>
          <Box sx={{ alignSelf: 'flex-end' }}>
            <Link to={URL_FORGOT_PASSWORD}>
              <Typography color="primary">Забыли пароль?</Typography>
            </Link>
          </Box>
          <Divider flexItem>или</Divider>
          <Button
            href={providers.google?.url}
            fullWidth
            sx={{
              border: '1px solid #4285f4',
              color: '#4285f4',
              fontWeight: 600,
              textTransform: 'none',
              borderRadius: '4px',
            }}
            startIcon={<img src={IconGoogleSVG} width={18} height={18} />}
          >
            Войти через Google
          </Button>
        </Stack>
      </Container>
      {loading && <SpinnerCover />}
    </>
  );
};

export default LoginForm;
