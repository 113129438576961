import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { URL_CHAT_ROOM } from '../../helpers/consts';
import { printTimestamp } from '../../helpers/utils';

const ChatCard = ({ room }) => {
  const { id, name, users, last_message } = room;

  function maskUserName(str) {
    str = str.replace('@', ' ');
    const arr = str.split(' ');
    return arr
      .flat()
      .map((el) =>
        el
          .split('')
          .map((l, i, a) => (i === 0 || i === a.length - 1 ? l : '*'))
          .join('')
      )
      .join(' ');
  }

  function getFirstLetters(str) {
    str = str.replace('@', ' ');
    const arr = str.split(' ');

    return arr
      .flat()
      .map((el) => el[0])
      .join('');
  }

  return (
    <Link
      to={URL_CHAT_ROOM.replace(':room_id', id)}
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          columnGap: '16px',
          padding: '16px 10px',
        }}
      >
        <Avatar alt={getFirstLetters(name)} sx={{ height: '64px', width: '64px' }}>
          {getFirstLetters(name)}
        </Avatar>
        <Box
          sx={{
            flexGrow: 1,
            minWidth: 0,
            display: 'flex',
            flexDirection: 'column',
            rowGap: '8px',
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              minWidth: 0,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography noWrap color="text.primary" sx={{ fontWeight: 700 }}>
              {maskUserName(name)}
            </Typography>
            {last_message && (
              <Typography variant="caption" color="text.secondary">
                {printTimestamp({ datetime: last_message.updated_at })}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              minWidth: 0,
              display: 'flex',
            }}
          >
            <Typography noWrap variant="subtitle2" color="text.secondary">
              {last_message ? last_message.message : 'Сообщений пока нет'}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

export default ChatCard;
