import React, { useRef, useState } from 'react';
import { Box, Button, Paper, Stack, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/lab';
import InputAdornment from '@mui/material/InputAdornment';
import { useAuth } from '../../contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import { URL_MENU } from '../../helpers/consts';
import { STYLES_BORDER_RADIUS } from '../../helpers/styles';

const ProfileEdit = () => {
  const history = useHistory();
  const { user, updateUserProfile } = useAuth();
  const profileFormRef = useRef();
  const [dob, setDob] = useState(user.user_profile?.dob || null);

  function handleCancelClick() {
    history.goBack();
  }

  async function handleSaveClick(e) {
    e.preventDefault();
    const profileForm = {
      id: user.user_profile?.id,
      firstName: profileFormRef.current.firstName.value,
      lastName: profileFormRef.current.lastName.value,
      phone: profileFormRef.current.phone.value,
      dob: dob,
    };
    await updateUserProfile(profileForm);
    history.push(URL_MENU);
  }

  return (
    <Paper
      sx={{
        width: '100%',
        padding: '32px',
        borderRadius: STYLES_BORDER_RADIUS,
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
        marginBottom: '10px',
      }}
    >
      <Stack component="form" ref={profileFormRef} spacing={3}>
        <Typography variant="h6" align="center">
          Редактировать
        </Typography>
        <TextField
          name="firstName"
          label="Имя"
          fullWidth
          variant="standard"
          defaultValue={user.user_profile?.first_name}
        />
        <TextField
          name="lastName"
          label="Фамилия"
          fullWidth
          variant="standard"
          defaultValue={user.user_profile?.last_name}
        />
        <TextField
          name="phone"
          label="Номер телефона"
          type="tel"
          fullWidth
          variant="standard"
          defaultValue={user.user_profile?.phone_number}
          InputProps={{
            startAdornment: <InputAdornment position="start">+996</InputAdornment>,
          }}
        />
        <DatePicker
          label="Дата рождения"
          openTo="year"
          allowSameDateSelection={true}
          inputFormat="DD-MMM-YYYY"
          views={['year', 'month', 'day']}
          value={dob}
          onChange={setDob}
          renderInput={(params) => <TextField variant="standard" {...params} />}
          okText={'Применить'}
          cancelText={'Оменить'}
          clearable={true}
          clearText={'Очистить'}
          showToolbar={true}
        />
        <TextField
          name="email"
          label="Электронная почта"
          fullWidth
          variant="standard"
          disabled
          defaultValue={user.user_email}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="outlined" color="secondary" onClick={handleCancelClick}>
            Отменить
          </Button>

          <Button type="submit" variant="contained" color="primary" onClick={handleSaveClick}>
            Сохранить
          </Button>
        </Box>
      </Stack>
    </Paper>
  );
};

export default ProfileEdit;
