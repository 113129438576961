import React from 'react';
import { Icon } from '@mui/material';
import AlbumRoundedIcon from '@mui/icons-material/AlbumRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';

const RouteCardCircle = ({ variant }) => {
  if (variant === 'from') {
    return (
      <Icon color="primary">
        <AlbumRoundedIcon />
      </Icon>
    );
  }
  if (variant === 'to') {
    return (
      <Icon color="secondary">
        <LocationOnRoundedIcon />
      </Icon>
    );
  }
};

export default RouteCardCircle;
