import React, { useRef } from 'react';
import SentRequestsList from '../../components/requests/SentRequestsList';
import LayoutContent from '../../layouts/LayoutContent';
import LayoutFooter from '../../layouts/LayoutFooter';
import LayoutHeader from '../../layouts/LayoutHeader';
import LayoutWrapper from '../../layouts/LayoutWrapper';

const SentRequestsPage = () => {
  const contentRef = useRef();

  return (
    <LayoutWrapper>
      <LayoutHeader title={'Избранное'} triggerTarget={contentRef.current} />
      <LayoutContent forwardRef={contentRef}>
        <SentRequestsList />
      </LayoutContent>
      <LayoutFooter />
    </LayoutWrapper>
  );
};

export default SentRequestsPage;
