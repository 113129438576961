import React, { useEffect } from 'react';
import { useData } from '../../contexts/DataContext';
import NoRecordsMessage from '../utils/NoRecordsMessage';
import SentRequestCard from './SentRequestCard';

const SentRequestsList = () => {
  const { getSentRequestsData, sent_requests } = useData();

  useEffect(() => {
    getSentRequestsData();
  }, []);
  return (
    <>
      {sent_requests && sent_requests.length !== 0 ? (
        sent_requests.map((item) => <SentRequestCard key={item.id} item={item} />)
      ) : (
        <NoRecordsMessage />
      )}
    </>
  );
};

export default SentRequestsList;
