import React from 'react';
import LoginForm from '../../components/auth/LoginForm';
import FooterLessLayout from '../../layouts/FooterLessLayout';

const LoginPage = () => {
  return (
    <FooterLessLayout>
      <LoginForm />
    </FooterLessLayout>
  );
};

export default LoginPage;
