import { STATUS_ERROR, STATUS_SUCCESS } from './consts';
import dayjs from 'dayjs';

// since all web requests are unreliable
// each request will be wrapped into try-catch block
export async function supervise_rq(req) {
  let resp = {};
  try {
    const result = await req();

    resp.status = STATUS_SUCCESS;
    resp.data = result.data;
  } catch (error) {
    resp.status = STATUS_ERROR;
    resp.data = error.response?.data;
  }
  return resp;
}

export function compileMessages(messages) {
  return Object.entries(messages)
    .map((arr) => arr.join(': '))
    .join('; ');
}

export function identifyRideTypes(rideTypesState) {
  const arr = [];
  Object.entries(rideTypesState).forEach(([key, val]) => val && arr.push(key));
  return arr;
}

export function printTimestamp({ datetime }) {
  return dayjs(datetime).add(6, 'hour').format('HH:mm');
}
