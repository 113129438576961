import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useStatus } from '../contexts/StatusContext';
import { useAuth } from './../contexts/AuthContext';
import { MESSAGE_UNAUTHENTICATED, URL_LOGIN } from './../helpers/consts';

const ProtectedRoute = ({ component: Component, role, ...rest }) => {
  const { user } = useAuth();
  const { setError } = useStatus();

  function redirect({ path, location }) {
    setError(MESSAGE_UNAUTHENTICATED);
    return <Redirect to={{ pathname: path, state: { from: location } }} />;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? <Component /> : redirect({ path: URL_LOGIN, location })
      }
    />
  );
};

export default ProtectedRoute;
