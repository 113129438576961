import React from 'react';
import { Box, Divider, Paper, Stack, Typography } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LanguageIcon from '@mui/icons-material/Language';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

const Settings = () => {
  return (
    <Paper
      sx={{
        padding: '16px 10px',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      }}
    >
      <Stack spacing={1} divider={<Divider orientation="horizontal" flexItem />}>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
          <NotificationsNoneIcon
            sx={{ color: '#4D4C4C', opacity: '87%', fontSize: 18, marginRight: '10px' }}
          />
          <Typography variant="h3" fontSize="0.8rem" sx={{ color: '#4D4C4C', flexGrow: '1' }}>
            Уведомления и звуки
          </Typography>
          <ArrowForwardIosRoundedIcon
            sx={{ color: '#4D4C4C', opacity: '57%', fontSize: 18, alignSelf: 'flex-end' }}
          />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
          <LockOpenIcon
            sx={{
              color: '#4D4C4C',
              opacity: '87%',
              fontSize: 18,
              marginRight: '10px',
            }}
          />
          <Typography variant="h3" fontSize="0.8rem" sx={{ color: '#4D4C4C', flexGrow: '1' }}>
            Приватность и Безопасность
          </Typography>
          <ArrowForwardIosRoundedIcon
            sx={{ color: '#4D4C4C', opacity: '57%', fontSize: 18, alignSelf: 'flex-end' }}
          />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
          <LanguageIcon
            sx={{ color: '#4D4C4C', opacity: '87%', fontSize: 18, marginRight: '10px' }}
          />
          <Typography variant="h3" fontSize="0.8rem" sx={{ color: '#4D4C4C', flexGrow: '1' }}>
            Язык
          </Typography>
          <ArrowForwardIosRoundedIcon
            sx={{ color: '#4D4C4C', opacity: '57%', fontSize: 18, alignSelf: 'flex-end' }}
          />
        </Box>
      </Stack>
    </Paper>
  );
};

export default Settings;
