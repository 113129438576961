import React, { useState, useEffect, useMemo } from 'react';
import { useStatus } from '../../contexts/StatusContext';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { STYLES_APP_MAX_WIDTH } from '../../helpers/styles';

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

function TransitionRight(props) {
  return <Slide {...props} direction="right" />;
}

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

//  this is a universal Notifications component to display and
//  inform about some event status and/or update to user

//  in order to watch for new messages, add another useEffect and define which variable you wish to depend on
const AlertsBar = () => {
  const { info, error, setInfo, setError } = useStatus();
  const [message, setMessage] = useState(null);
  const [severity, setSeverity] = useState(null);
  const [open, setOpen] = useState(false);
  const [transition, setTransition] = useState(null);

  useEffect(() => {
    if (info) {
      setMessage(info);
      setSeverity('success');
    }
    if (error) {
      setMessage(error);
      setSeverity('error');
    }
    setTransition(() => TransitionDown);
    setOpen(true);
  }, [info, error]);

  function handleClose() {
    if (severity === 'success') setInfo(null);
    if (severity === 'error') setError(null);
    setOpen(false);
  }

  const Bar = useMemo(
    () => (
      <Snackbar
        open={open}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
        TransitionComponent={transition}
        key={Date.now()}
        sx={{
          width: '100%',
          maxWidth: STYLES_APP_MAX_WIDTH,
          pl: '8px',
          pr: '8px',
          left: 'auto',
          right: 'auto',
        }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    ),
    [message, open]
  );

  return <>{message && Bar}</>;
};

export default AlertsBar;

// import * as React from 'react';
// import Button from '@mui/material/Button';
// import Snackbar from '@mui/material/Snackbar';
// import Slide from '@mui/material/Slide';

// function TransitionLeft(props) {
//   return <Slide {...props} direction="left" />;
// }

// function TransitionUp(props) {
//   return <Slide {...props} direction="up" />;
// }

// function TransitionRight(props) {
//   return <Slide {...props} direction="right" />;
// }

// function TransitionDown(props) {
//   return <Slide {...props} direction="down" />;
// }

// export default function DirectionSnackbar() {
//   const [open, setOpen] = React.useState(false);
//   const [transition, setTransition] = React.useState(undefined);

//   const handleClick = (Transition) => () => {
//     setTransition(() => Transition);
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   return (
//     <div>
//       <Button onClick={handleClick(TransitionLeft)}>Right</Button>
//       <Button onClick={handleClick(TransitionUp)}>Up</Button>
//       <Button onClick={handleClick(TransitionRight)}>Left</Button>
//       <Button onClick={handleClick(TransitionDown)}>Down</Button>
//       <Snackbar
//         open={open}
//         onClose={handleClose}
//         TransitionComponent={transition}
//         message="I love snacks"
//         key={transition ? transition.name : ''}
//       />
//     </div>
//   );
// }
