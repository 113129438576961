import React, { useEffect, useState } from 'react';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Stack } from '@mui/material';
import { URL_ROUTES_CREATE } from '../../helpers/consts';
import { Link } from 'react-router-dom';
import RoutesCard from './RoutesCard';
import NoRecordsMessage from '../utils/NoRecordsMessage';
import { useData } from '../../contexts/DataContext';

const OwnRoutesList = () => {
  const { own_routes, getOwnRoutesData } = useData();

  useEffect(() => {
    getOwnRoutesData();
  }, []);

  const [expandedRoute, setExpandedRoute] = useState(null);

  function handleExpand(route) {
    setExpandedRoute((prevState) => {
      if (prevState === null) return route;
      else if (route.id === prevState.id) return null;
      else return route;
    });
  }

  return (
    <>
      <Stack spacing={2} marginBottom="16px">
        {own_routes && own_routes.length !== 0 ? (
          own_routes.map((item) => (
            <RoutesCard
              key={item.id}
              item={item}
              handleExpandClick={handleExpand}
              expandedRoute={expandedRoute}
              ownRoute={true}
            />
          ))
        ) : (
          <NoRecordsMessage />
        )}
      </Stack>

      <Link to={URL_ROUTES_CREATE}>
        <Fab color="primary" sx={{ position: 'fixed', bottom: '76px', right: '26px' }}>
          <AddIcon />
        </Fab>
      </Link>
    </>
  );
};

export default OwnRoutesList;
