// HOW TO USE:

// STEP 1:
// import functions from context in to your component
// const { setError, setInfo } = useStatus();

// STEP 2:
// call imported functions when needed.
// below is an example to set error to 'true' and to add some message to be displayed on component mount
// useEffect(() => {
//   setInfo("some error");
//   setError(true);
// }, []);

import React, { createContext, useContext, useReducer } from 'react';
import { SET_INFO, SET_ERROR, SET_LOADING } from '../helpers/consts';

const statusContext = createContext();

export const useStatus = () => {
  return useContext(statusContext);
};

const INIT_STATE = {
  loading: false,
  error: false,
  info: null,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_INFO:
      return {
        ...state,
        info: action.payload,
      };
    default:
      return state;
  }
};

const StatusContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INIT_STATE);

  // update loading state before and after axios calls
  function setLoading(status) {
    dispatch({
      type: SET_LOADING,
      payload: status,
    });
  }

  // update the error if there is one
  function setError(status) {
    dispatch({
      type: SET_ERROR,
      payload: status,
    });
  }

  // update the message to be displayed in NotificationBar
  function setInfo(message) {
    dispatch({
      type: SET_INFO,
      payload: message,
    });
  }

  const values = {
    loading: state.loading,
    error: state.error,
    info: state.info,

    setLoading,
    setError,
    setInfo,
  };

  return <statusContext.Provider value={values}>{children}</statusContext.Provider>;
};

export default StatusContextProvider;
