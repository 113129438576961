import React, { useRef } from 'react';
import ReceivedRequestsList from '../../components/requests/ReceivedRequestsList';
import LayoutContent from '../../layouts/LayoutContent';
import LayoutFooter from '../../layouts/LayoutFooter';
import LayoutHeader from '../../layouts/LayoutHeader';
import LayoutWrapper from '../../layouts/LayoutWrapper';

const ReceivedRequestsPage = () => {
  const contentRef = useRef();

  return (
    <LayoutWrapper>
      <LayoutHeader title={'Запросы'} triggerTarget={contentRef.current} />
      <LayoutContent forwardRef={contentRef}>
        <ReceivedRequestsList />
      </LayoutContent>
      <LayoutFooter />
    </LayoutWrapper>
  );
};

export default ReceivedRequestsPage;
