import { Button } from '@mui/material';
import React from 'react';

const SubmitButton = ({
  text,
  variant = 'contained',
  color = 'primary',
  startIcon,
  handleClick,
  borderRadius = '16px',
}) => {
  return (
    <Button
      type="submit"
      fullWidth
      variant={variant}
      color={color}
      startIcon={startIcon}
      onClick={handleClick}
      sx={{ pt: '12px', pb: '12px', borderRadius: borderRadius }}
    >
      {text}
    </Button>
  );
};

export default SubmitButton;
