import React, { useRef } from 'react';
import { Stack } from '@mui/material';
import ProfilePreview from '../../components/profile/ProfilePreview';
import Settings from '../../components/settings/Settings';
import LogoutMenuItem from '../../components/utils/LogoutMenuItem';
import MyRoutesMenuItem from '../../components/utils/MyRoutesMenuItem';
import LayoutWrapper from '../../layouts/LayoutWrapper';
import LayoutHeader from '../../layouts/LayoutHeader';
import LayoutContent from '../../layouts/LayoutContent';
import LayoutFooter from '../../layouts/LayoutFooter';

const MenuPage = () => {
  const contentRef = useRef();

  return (
    <LayoutWrapper>
      <LayoutHeader title={'Меню'} triggerTarget={contentRef.current} />
      <LayoutContent forwardRef={contentRef}>
        <Stack spacing={3}>
          <ProfilePreview />
          <MyRoutesMenuItem />
          <Settings />
          <LogoutMenuItem />
        </Stack>
      </LayoutContent>
      <LayoutFooter />
    </LayoutWrapper>
  );
};

export default MenuPage;
