// Backend API paths
// export const HOST = 'localhost:4000';
export const HOST = 'be.sharit.kg';
export const HTTP_URL = `https://${HOST}`;
export const WS_URL = `wss://${HOST}`;
export const API = `${HTTP_URL}/api/v1`;

// Client URL Paths
export const URL_HOME = '/';
export const URL_ROUTES = '/routes';
export const URL_ROUTES_CREATE = '/routes/new';
export const URL_SENT_REQUESTS = '/requests/sent_requests';
export const URL_RECEIVED_REQUESTS = '/requests/received_requests';
export const URL_CHAT = '/chat';
export const URL_CHAT_ROOM = '/chat/:room_id';
export const URL_OWN_ROUTES = '/own_routes';
export const URL_PROFILE_EDIT = '/profile/edit';
export const URL_EDIT_ROUTE = '/edit_route/:route_id';
export const URL_MENU = '/menu';

export const URL_REGISTRATION = '/auth/registration';
export const URL_LOGIN = '/auth/login';
export const URL_LOGIN_VIA_3RD_PARTY = '/auth/:provider/callback';
export const URL_EMAIL_CONFIRMATION = '/auth/confirm_email/:token';

export const URL_FORGOT_PASSWORD = '/auth/forgot_password';
export const URL_RESET_PASSWORD = '/auth/reset_password/:token';

// API URL Paths
export const API_AUTH_REGISTRATION = `${API}/registration`;
export const API_AUTH_CONFIRM_EMAIL = `${API}/confirm_email`;
export const API_AUTH_LOGIN = `${API}/session`;
export const API_AUTH_RENEW = `${API}/session/renew`;
export const API_AUTH_RESET_PASSWORD = `${API}/reset_password`;
export const API_AUTH_3RD_PARTY_PROVIDER_URL = (provider) => `${API}/auth/${provider}/new`;
export const API_AUTH_3RD_PARTY_VERIFY_URL = (provider) => `${API}/auth/${provider}/callback`;

export const API_PROFILES = `${API}/user_profiles`;

export const API_ROUTES = `${API}/routes`;
export const API_OWN_ROUTES = `${API}/own_routes`;
export const API_SHARE_REQUESTS = `${API}/share_requests`;
export const API_USER_PROFILES = `${API}/user_profiles`;
export const API_SENT_REQUESTS = `${API}/share_requests?type=sent`;
export const API_RECEIVED_REQUESTS = `${API}/share_requests?type=received`;
export const API_CHAT_ROOMS = `${API}/chat_rooms`;
export const API_CHAT_MESSAGES = `${API}/chat_messages`;
export const API_USERS = `${API}/users`;

// StatusContext's reducer actions
export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const SET_INFO = 'SET_INFO';

// SocketContext's reducer actions
export const OPEN_SOCKET = 'OPEN_SOCKET';

// AuthContext's reducer actions
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const GET_PROVIDER_DATA = 'GET_PROVIDER_DATA';
export const UPDATE_PROFILE_DATA = 'UPDATE_PROFILE_DATA';

// DataContext's consts:
export const PER_PAGE = 5;
export const RIDE_TYPES = ['walk', 'bus', 'taxi', 'car'];
export const STATUS_SUCCESS = 'success';
export const STATUS_ERROR = 'error';
export const GET_ROUTES = 'GET_ROUTES';
export const GET_OWN_ROUTES = 'GET_OWN_ROUTES';
export const GET_SENT_REQUESTS = 'GET_SENT_REQUESTS';
export const GET_RECEIVED_REQUESTS = 'GET_RECEIVED_REQUESTS';
export const GET_CHAT_ROOMS = 'GET_CHAT_ROOMS';
export const GET_CHAT_MESSAGES = 'GET_CHAT_MESSAGES';
export const GET_ROUTE_DETAILS = 'GET_ROUTE_DETAILS';

// GoogleMaps API Key
export const GMAPS_API_KEY = 'AIzaSyBXPhtpgIHag2KP5GYHwlMz5l7tKh5_Sf0';

// Messages
export const MESSAGE_UNAUTHENTICATED = 'You need to Login first to access this part of an App';
export const MESSAGE_LOADING_FAILED = 'Data could not be loaded at this time';
export const MESSAGE_RECORDS_LOADED = (count) => `Successfully loaded ${count} records`;
