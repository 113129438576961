import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { STYLES_BORDER_RADIUS } from '../../helpers/styles';
import RideTypesBar from '../utils/RideTypesBar';
import RouteCardPathDecorator from '../utils/RouteCardPathDecorator';
import RouteCardLocations from '../utils/RouteCardLocations';
import { URL_EDIT_ROUTE, URL_OWN_ROUTES } from '../../helpers/consts';
import { useAuth } from '../../contexts/AuthContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SubmitButton from '../utils/SubmitButton';
import { useData } from '../../contexts/DataContext';
import { Icon } from '@mui/material';
import { pink } from '@mui/material/colors';
import DayOfWeekBar from '../utils/DayOfWeekBar';
import RouteCardValidityPeriod from '../utils/RouteCardValidityPeriod';
import RouteCardTimeSchedule from '../utils/RouteCardTimeSchedule';

const RoutesCard = ({
  item,
  handleShareClick,
  cardRef,
  ownRoute,
  liked,
  expandedRoute,
  handleExpandClick,
}) => {
  const { user } = useAuth();
  const { deleteOwnRoute, getOwnRoutesData } = useData();
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const {
    arrival_time,
    departure_time,
    description,
    dow,
    from_location,
    id,
    is_active,
    money,
    ride_types,
    title,
    to_location,
    user_id: authors_id,
    valid_from,
    valid_till,
  } = item;

  useEffect(() => {
    expandIfThisSelected();
  }, [expandedRoute]);

  function expandIfThisSelected() {
    if (expandedRoute === null) setExpanded(false);
    else if (id === expandedRoute.id) setExpanded(true);
    else setExpanded(false);
  }

  const handleEdit = () => {
    history.push(URL_EDIT_ROUTE.replace(':route_id', id));
  };

  async function handleDelete() {
    await deleteOwnRoute(id);
    getOwnRoutesData();
  }

  return (
    <Card
      ref={cardRef}
      sx={{
        // maxWidth: 360,
        // margin: '0 auto 16px',
        borderRadius: STYLES_BORDER_RADIUS,
      }}
    >
      <CardContent onClick={() => handleExpandClick(item)} sx={{ pb: 0 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', columnGap: '16px' }}>
          <RouteCardPathDecorator />
          <RouteCardLocations fromLocation={from_location} toLocation={to_location} />
          <RouteCardTimeSchedule departure_time={departure_time} arrival_time={arrival_time} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '16px',
          }}
        >
          <Box>
            <RideTypesBar rideTypes={ride_types} readOnly={true} />
            <Box sx={{ marginTop: '8px' }}>
              <RouteCardValidityPeriod valid_from={valid_from} valid_till={valid_till} />
              <DayOfWeekBar checkedState={dow} />
            </Box>
          </Box>
          <Box sx={{ width: 40, height: '100%', display: 'flex', justifyContent: 'center' }}>
            {liked ? (
              <Icon
                sx={{
                  width: 30,
                  height: 30,
                  backgroundColor: pink[100],
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <FavoriteRoundedIcon
                  sx={{
                    fontSize: '18px',
                    color: pink[500],
                  }}
                />
              </Icon>
            ) : null}
          </Box>
        </Box>
      </CardContent>
      <CardActions></CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent onClick={() => handleExpandClick(item)} sx={{ pt: 0 }}>
          <>
            <Typography color="text.disabled" fontSize="0.85em">
              Бюджет
            </Typography>
            <Typography fontWeight="bold" mb={2}>
              {money === 0 || money ? `${money} сом` : 'не имеет значения'}
            </Typography>
          </>
          {window.location.pathname === URL_OWN_ROUTES ? (
            <>
              <Typography color="text.disabled" fontSize="0.85em">
                Название
              </Typography>
              <Typography mb={2}>{title}</Typography>
            </>
          ) : null}

          {description && (
            <>
              <Typography color="text.disabled" fontSize="0.85em">
                Описание
              </Typography>
              <Typography mb={2}>{description}</Typography>
            </>
          )}
          {window.location.pathname === URL_OWN_ROUTES ? (
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="outlined" color="secondary" onClick={handleDelete}>
                Удалить
              </Button>
              <Button type="submit" variant="contained" color="primary" onClick={handleEdit}>
                Редактировать
              </Button>
            </Box>
          ) : liked ? null : (
            <SubmitButton text={'Выбрать'} handleClick={() => handleShareClick(item)} />
          )}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default RoutesCard;
