import React from 'react';
import { Box } from '@mui/material';
import Spinner from './Spinner';

const SpinnerCover = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
      }}
    >
      <Spinner />
    </Box>
  );
};

export default SpinnerCover;
