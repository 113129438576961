import CancelRounded from '@mui/icons-material/CancelRounded';
import { Box, IconButton } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import AlertsBar from '../components/utils/AlertsBar';
import DataContextProvider from '../contexts/DataContext';

const FooterLessLayout = ({ backgroundColor = 'background.paper', children }) => {
  const history = useHistory();

  function handleClose() {
    history.goBack();
    // history.push(URL_HOME);

    // setTimeout(() => {
    //   history.goBack();
    // }, ANIMATION_TIMEOUT);
  }

  return (
    <DataContextProvider>
      <AlertsBar />
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}>
        <Box sx={{ alignSelf: 'flex-end', display: 'flex', alignItems: 'flex-start' }}>
          <IconButton onClick={handleClose}>
            <CancelRounded />
          </IconButton>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            pl: '16px',
            pr: '16px',
            borderTopLeftRadius: '24px',
            borderTopRightRadius: '24px',
            backgroundColor: backgroundColor,
            maxHeight: 'calc(100% - 48px)',
            overflowY: 'hidden',
          }}
        >
          {children}
        </Box>
      </Box>
    </DataContextProvider>
  );
};

export default FooterLessLayout;
