import MapRoundedIcon from '@mui/icons-material/MapRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

import {
  URL_CHAT,
  URL_CHAT_ROOM,
  URL_HOME,
  URL_MENU,
  URL_OWN_ROUTES,
  URL_RECEIVED_REQUESTS,
  URL_SENT_REQUESTS,
} from '../helpers/consts';

export const ROUTES_TABLE = [
  {
    label: 'Routes',
    url: URL_HOME,
    icon: <MapRoundedIcon />,
    sub_routes: [],
  },
  {
    label: 'Sent Requests',
    url: URL_SENT_REQUESTS,
    icon: <FavoriteRoundedIcon />,
    sub_routes: [],
  },
  {
    label: 'Chat',
    url: URL_CHAT,
    icon: <ForumRoundedIcon />,
    sub_routes: [
      {
        label: 'Chat Room',
        url: URL_CHAT_ROOM,
      },
    ],
  },
  {
    label: 'Received Requests',
    url: URL_RECEIVED_REQUESTS,
    icon: <NotificationsRoundedIcon />,
    sub_routes: [],
  },
  {
    label: 'Menu',
    url: URL_MENU,
    icon: <MenuRoundedIcon />,
    sub_routes: [
      {
        label: 'My Routes',
        url: URL_OWN_ROUTES,
      },
    ],
  },
];
