import React, { useState } from 'react';
import { Global } from '@emotion/react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Button, IconButton, Stack, SwipeableDrawer } from '@mui/material';
import RideTypesBar from './RideTypesBar';
import { useData } from '../../contexts/DataContext';
import { useHistory } from 'react-router-dom';
import { useRef } from 'react';
import { identifyRideTypes } from '../../helpers/utils';
import { STYLES_BORDER_RADIUS } from '../../helpers/styles';
import Puller from './Puller';
import SubmitButton from './SubmitButton';

const FilterBar = () => {
  const { getRoutesData } = useData();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [rideTypes, setRideTypes] = useState({});
  const refForm = useRef();

  function openDrawer() {
    setOpen(true);
  }

  function closeDrawer() {
    setOpen(false);
  }

  function handleSubmit(e) {
    e.preventDefault();
    const search = new URLSearchParams(history.location.search);

    // ! for filters based on ride type
    const rideTypesStr = identifyRideTypes(rideTypes).join(',');

    if (rideTypesStr === '') {
      search.delete('ride_types');
    } else {
      search.set('ride_types', rideTypesStr);
    }

    // search.set('page', 1);
    history.push(`${history.location.pathname}?${search.toString()}`);

    getRoutesData();
  }

  function handleReset(e) {
    e.preventDefault();
    const search = new URLSearchParams(history.location.search);
    search.delete('ride_types');
    history.push(`${history.location.pathname}?${search.toString()}`);
    getRoutesData();
  }

  return (
    <>
      <IconButton onClick={openDrawer}>
        <FilterListIcon />
      </IconButton>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            backgroundColor: 'transparent',
          },
        }}
      />
      <SwipeableDrawer
        anchor="top"
        open={open}
        onOpen={openDrawer}
        onClose={closeDrawer}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Stack
          component="form"
          ref={refForm}
          spacing={2}
          onKeyDown={closeDrawer}
          sx={{
            padding: '0 16px',
            backgroundColor: 'whitesmoke',
            borderBottomLeftRadius: STYLES_BORDER_RADIUS,
            borderBottomRightRadius: STYLES_BORDER_RADIUS,
          }}
        >
          <Button
            fullWidth
            variant="text"
            sx={{
              textTransform: 'capitalize',
              textAlign: 'right',
              justifyContent: 'flex-end',
            }}
            onClick={handleReset}
          >
            Reset
          </Button>
          <RideTypesBar
            readOnly={false}
            checkedState={rideTypes}
            handleChange={setRideTypes}
            justifyContent="space-between"
          />
          <SubmitButton text={'Применить'} handleClick={handleSubmit} />
          <Puller onClick={closeDrawer} />
        </Stack>
      </SwipeableDrawer>
    </>
  );
};

export default FilterBar;
