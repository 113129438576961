import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useAuth } from '../../contexts/AuthContext';
import { useStatus } from '../../contexts/StatusContext';
import { URL_LOGIN } from '../../helpers/consts';
import SubmitButton from '../utils/SubmitButton';
import SpinnerCover from '../utils/SpinnerCover';
import rounded_icon from '../../assets/rounded_icon.ico';

const RegistrationForm = () => {
  const formRef = useRef();
  const { register } = useAuth();
  const { loading } = useStatus();

  function handleSubmit(e) {
    e.preventDefault();
    const {
      current: { email, password, repeat_password },
    } = formRef;

    if (password.value.length < 8) {
      alert('Минимальное кол-во символов для пароля 8');
      return;
    }

    if (password.value !== repeat_password.value) {
      alert('Пароли не совпадают! Введите заново');
      return;
    }

    const newUser = {
      email: email.value,
      password: password.value,
      repeat_password: repeat_password.value,
    };
    register(newUser);
    email.value = '';
    password.value = '';
    repeat_password.value = '';
  }

  return (
    <>
      <Container>
        <Stack
          ref={formRef}
          component={'form'}
          direction={'column'}
          spacing={2}
          display={'flex'}
          alignItems={'center'}
          mt={6}
        >
          <img src={rounded_icon} alt="sharit_logo" width="45px" height="45px" />

          <Typography component="h1" variant="h5">
            Регистрация
          </Typography>

          <TextField
            autoFocus
            name="email"
            required
            fullWidth
            id="email"
            label="Электронная почта"
            autoComplete="email"
          />

          <TextField
            required
            fullWidth
            id="password"
            label="Пароль"
            type="password"
            name="password"
            autoComplete="new-password"
          />

          <TextField
            required
            fullWidth
            name="repeat_password"
            label="Пароль повторно"
            type="password"
            id="repeat_password"
            autoComplete="new-password"
          />

          <SubmitButton text={'Зарегистрироваться'} handleClick={handleSubmit} />

          <Box alignSelf={'flex-end'}>
            <Link to={URL_LOGIN}>
              <Typography variant="string" color="text.primary">
                Уже есть аккаунт?
              </Typography>
              <Typography variant="string" color="primary" ml={1}>
                Войти
              </Typography>
            </Link>
          </Box>
        </Stack>
      </Container>
      {loading && <SpinnerCover />}
    </>
  );
};

export default RegistrationForm;
