import React, { useEffect } from 'react';
import { Divider, Stack } from '@mui/material';
import ChatCard from './ChatCard';
import { useData } from '../../contexts/DataContext';
import NoRecordsMessage from '../utils/NoRecordsMessage';

const ChatList = () => {
  const { getChatRoomsData, chat_rooms } = useData();

  useEffect(() => {
    getChatRoomsData();
  }, []);

  return (
    <Stack
      // sx={{ backgroundColor: 'background.paper', height: '100%' }}
      direction="column"
      justifyContent="flex-start"
      divider={<Divider orientation="horizontal" flexItem />}
      spacing={1}
    >
      {chat_rooms && chat_rooms.data.map((room) => <ChatCard key={room.id} room={room} />)}
      {chat_rooms && chat_rooms.data.length === 0 && <NoRecordsMessage />}
    </Stack>
  );
};
export default ChatList;
