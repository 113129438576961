import React from 'react';
import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';
import { Icon, IconButton, useTheme } from '@mui/material';
import { matchPath } from 'react-router-dom';
import { ROUTES_TABLE } from '../routes/RoutesTable';
import { STYLES_APP_MAX_WIDTH, STYLES_FOOTER_HEIGHT } from '../helpers/styles';

const LayoutFooter = () => {
  const theme = useTheme();
  const greyColor = theme.palette.grey[800];
  const activeColor = theme.palette.primary.main;

  function isMatched(url, path) {
    const match = matchPath(url, {
      path: path,
      exact: true,
      strict: false,
    });
    return Boolean(match);
  }

  function handleIsActive(match, location, navUrl) {
    if (match) {
      return true;
    } else if (match === null) {
      const isMatch = ROUTES_TABLE.find((r) => {
        if (r.url === navUrl) {
          const isSubRouteMatch = r.sub_routes.find((sr) => isMatched(location.pathname, sr.url));
          return Boolean(isSubRouteMatch);
        }
      });
      return Boolean(isMatch);
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: `${STYLES_FOOTER_HEIGHT}px`,
        position: 'inherit',
        width: '100%',
        maxWidth: STYLES_APP_MAX_WIDTH,
        bottom: 0,
        left: 'auto',
        right: 'auto',
        backgroundColor: 'white',
      }}
    >
      {ROUTES_TABLE.map(({ url, icon }, index) => {
        return (
          <NavLink
            key={index}
            style={{ color: greyColor }}
            activeStyle={{ color: activeColor }}
            exact
            isActive={(match, location) => handleIsActive(match, location, url)}
            to={url}
          >
            <IconButton color="inherit">
              <Icon>{icon}</Icon>
            </IconButton>
          </NavLink>
        );
      })}
    </Box>
  );
};

export default LayoutFooter;
