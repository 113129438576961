import React from 'react';
import EmailConfirmation from '../../components/auth/EmailConfirmation';
import FooterLessLayout from '../../layouts/FooterLessLayout';

const EmailConfirmationPage = () => {
  return (
    <FooterLessLayout>
      <EmailConfirmation />
    </FooterLessLayout>
  );
};

export default EmailConfirmationPage;
