import React from 'react';
import { Box } from '@mui/material';
import { STYLES_FOOTER_HEIGHT, STYLES_HEADER_HEIGHT } from '../helpers/styles';

const LayoutContent = ({
  children,
  headerHeight = STYLES_HEADER_HEIGHT,
  footerHeight = STYLES_FOOTER_HEIGHT,
  pl = 16,
  pr = 16,
  forwardRef,
}) => {
  return (
    <Box
      ref={forwardRef}
      sx={{
        height: `calc(100% - ${footerHeight}px)`,
        // height: '100%',
        // pt: `${headerHeight + 16}px`,
        pl: `${pl}px`,
        pr: `${pr}px`,
        // pb: `${footerHeight}px`,
        // overflowX: 'hidden',
        overflowY: 'scroll',
      }}
    >
      <Box
        sx={{
          height: headerHeight === STYLES_HEADER_HEIGHT ? STYLES_HEADER_HEIGHT + 16 : headerHeight,
        }}
      ></Box>
      {children}
    </Box>
  );
};

export default LayoutContent;
