import React from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import SubmitButton from './SubmitButton';
import { useAuth } from '../../contexts/AuthContext';

const LogoutMenuItem = () => {
  const { logout } = useAuth();

  function handleLogOut() {
    logout();
  }

  return (
    <SubmitButton
      text={'Выйти'}
      variant="outlined"
      color="secondary"
      startIcon={<LogoutIcon />}
      handleClick={handleLogOut}
    />
  );
};

export default LogoutMenuItem;
