import React, { useEffect, useRef, useState } from 'react';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import { useSocket } from '../../contexts/SocketContext';
import { useParams } from 'react-router-dom';
import { useData } from '../../contexts/DataContext';
import { Stack, Box } from '@mui/material';
import {
  STYLES_APP_MAX_WIDTH,
  STYLES_FOOTER_HEIGHT,
  STYLES_HEADER_HEIGHT,
} from '../../helpers/styles';
import { useAuth } from '../../contexts/AuthContext';
import MessageBox from './MessageBox';
import NoRecordsMessage from '../utils/NoRecordsMessage';
import ScrollToBottom from 'react-scroll-to-bottom';

const MessagesList = ({ forwardRef }) => {
  const { getOldMessages, messages, addNewMessage } = useData();
  const { user } = useAuth();
  const { socket } = useSocket();
  const { room_id } = useParams();
  const topic = 'chat';
  const subTopic = room_id;
  const channelName = `${topic}:${subTopic}`;

  const [channel, setChannel] = useState(null);
  const messageInputRef = useRef();

  useEffect(() => {
    if (!socket) return;

    const channel = socket.channel(channelName, {});
    channel
      .join()
      .receive('ok', (resp) => {
        getOldMessages(resp);
      })
      .receive('error', (resp) => {});

    channel.on('new_msg', (payload) => {
      addNewMessage(payload);
    });

    setChannel(channel);
  }, [socket]);

  function handleKeypress(e) {
    if (e.charCode === 13) {
      sendMessage();
    }
  }

  function sendMessage() {
    channel.push('new_msg', { body: messageInputRef.current.value });
    messageInputRef.current.value = '';
  }

  return (
    <Box
      ref={forwardRef}
      sx={{
        height: `calc(100% - ${STYLES_FOOTER_HEIGHT}px)`,
        // height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ScrollToBottom className="h-100">
        <Stack
          spacing={1}
          sx={{
            flexGrow: 1,
            pl: '16px',
            pr: '16px',
            // height: `calc(100vh - ${STYLES_FOOTER_HEIGHT}px)`,
          }}
        >
          <Box sx={{ height: STYLES_HEADER_HEIGHT }}></Box>
          {messages && messages.length !== 0 ? (
            messages.map((item) => (
              <MessageBox key={item.id} item={item} isMyMessage={item.user_id === user.user_id} />
            ))
          ) : (
            <NoRecordsMessage />
          )}
        </Stack>
      </ScrollToBottom>

      <Paper
        elevation={0}
        component="form"
        sx={{
          // position: 'fixed',
          width: '100%',
          maxWidth: STYLES_APP_MAX_WIDTH,
          // left: 'auto',
          // right: 'auto',
          // bottom: STYLES_FOOTER_HEIGHT,
          display: 'flex',
          alignItems: 'center',
          padding: '8px 16px',
          borderRadius: 0,
        }}
      >
        <InputBase
          inputRef={messageInputRef}
          multiline
          maxRows={4}
          sx={{
            flex: 1,
            backgroundColor: 'background.default',
            borderRadius: '16px',
            padding: '8px 16px',
          }}
          placeholder="Сообщение..."
        />
        {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
        <IconButton color="primary" onClick={sendMessage} sx={{ pr: 0 }}>
          <SendRoundedIcon />
        </IconButton>
      </Paper>
    </Box>
  );
};

export default MessagesList;
