import React from 'react';
import TextField from '@mui/material/TextField';
import TimePicker from '@mui/lab/TimePicker';
import MobileTimePicker from '@mui/lab/MobileTimePicker';

const RouteTimePicker = ({ label, value, setValue }) => {
  return (
    <MobileTimePicker
      label={label}
      value={value}
      onChange={setValue}
      minutesStep={5}
      ampm={false}
      renderInput={(params) => <TextField {...params} />}
      okText={'Применить'}
      cancelText={'Оменить'}
      clearable={true}
      clearText={'Очистить'}
      showToolbar={true}
    />
  );
};

export default RouteTimePicker;
