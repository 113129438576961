import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useData } from '../../contexts/DataContext';
import RouteForm from './RouteForm';

const EditRouteForm = () => {
  const { route_id } = useParams();
  const { route_details, editRoute, getRouteDetails } = useData();

  useEffect(() => {
    getRouteDetails(route_id);
  }, []);

  function handleSubmit(formObj) {
    const { id, user_id } = route_details;
    editRoute({ ...formObj, id, user_id });
  }

  return (
    route_details && (
      <RouteForm route={route_details} handleSubmit={handleSubmit} submitText={'Сохранить'} />
    )
  );
};

export default EditRouteForm;
