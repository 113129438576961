import React from 'react';
import { Paper, Typography } from '@mui/material';
import { Box } from '@mui/material';
import RouteIcon from '@mui/icons-material/Route';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Link } from 'react-router-dom';
import { URL_OWN_ROUTES } from '../../helpers/consts';

const MyRoutesMenuItem = () => {
  return (
    <Link to={URL_OWN_ROUTES}>
      <Paper
        sx={{
          padding: '16px 12px',
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <RouteIcon sx={{ color: 'gray', fontSize: 18, marginRight: '10px' }} />
          <Typography variant="h3" fontSize="0.8rem" sx={{ color: '#4D4C4C', flexGrow: '1' }}>
            Мои маршруты
          </Typography>
          <ArrowForwardIosRoundedIcon sx={{ color: '#4D4C4C', opacity: '57%', fontSize: 18 }} />
        </Box>
      </Paper>
    </Link>
  );
};

export default MyRoutesMenuItem;
