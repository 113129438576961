import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import SpinnerCover from '../components/utils/SpinnerCover.jsx';
import { useAuth } from '../contexts/AuthContext.js';
import SocketContextProvider from '../contexts/SocketContext.js';
import EmailConfirmationPage from '../pages/auth_pages/EmailConfirmationPage.jsx';
import ForgotPasswordPage from '../pages/auth_pages/ForgotPasswordPage.jsx';
import LoginPage from '../pages/auth_pages/LoginPage.jsx';
import RegistrationPage from '../pages/auth_pages/RegistrationPage.jsx';
import ResetPasswordPage from '../pages/auth_pages/ResetPasswordPage.jsx';
import ChatRoomPage from '../pages/chat_pages/ChatRoomPage.jsx';
import ChatsPage from '../pages/chat_pages/ChatsPage.jsx';
import MenuPage from '../pages/menu_pages/MenuPage.jsx';
import ProfileEditPage from '../pages/profile_pages/ProfileEditPage.jsx';
import ReceivedRequestsPage from '../pages/requests_pages/ReceivedRequestsPage.jsx';
import SentRequestsPage from '../pages/requests_pages/SentRequestsPage.jsx';
import CreateRoutePage from '../pages/routes_pages/CreateRoutePage.jsx';
import EditRoutePage from '../pages/routes_pages/EditRoutePage.jsx';
import OwnRoutesPage from '../pages/routes_pages/OwnRoutesPage.jsx';
import RoutesPage from '../pages/routes_pages/RoutesPage.jsx';
import {
  URL_CHAT,
  URL_CHAT_ROOM,
  URL_EDIT_ROUTE,
  URL_EMAIL_CONFIRMATION,
  URL_FORGOT_PASSWORD,
  URL_HOME,
  URL_LOGIN,
  URL_LOGIN_VIA_3RD_PARTY,
  URL_MENU,
  URL_OWN_ROUTES,
  URL_PROFILE_EDIT,
  URL_RECEIVED_REQUESTS,
  URL_REGISTRATION,
  URL_RESET_PASSWORD,
  URL_ROUTES,
  URL_ROUTES_CREATE,
  URL_SENT_REQUESTS,
} from './../helpers/consts.js';
import ProtectedRoute from './ProtectedRoute.js';

const Routes = () => {
  const { authLoading } = useAuth();

  return authLoading ? (
    <SpinnerCover />
  ) : (
    <SocketContextProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path={URL_HOME} component={RoutesPage} />
          <Route exact path={URL_ROUTES} component={RoutesPage} />

          <ProtectedRoute exact path={URL_EDIT_ROUTE} component={EditRoutePage} />

          <ProtectedRoute exact path={URL_SENT_REQUESTS} component={SentRequestsPage} />
          <ProtectedRoute exact path={URL_RECEIVED_REQUESTS} component={ReceivedRequestsPage} />

          <ProtectedRoute exact path={URL_CHAT} component={ChatsPage} />
          <ProtectedRoute exact path={URL_CHAT_ROOM} component={ChatRoomPage} />

          <ProtectedRoute exact path={URL_MENU} component={MenuPage} />
          <ProtectedRoute exact path={URL_PROFILE_EDIT} component={ProfileEditPage} />
          <ProtectedRoute exact path={URL_OWN_ROUTES} component={OwnRoutesPage} />

          <ProtectedRoute exact path={URL_ROUTES_CREATE} component={CreateRoutePage} />

          <Route exact path={URL_LOGIN} component={LoginPage} />
          <Route exact path={URL_LOGIN_VIA_3RD_PARTY} component={LoginPage} />
          <Route exact path={URL_REGISTRATION} component={RegistrationPage} />
          <Route exact path={URL_EMAIL_CONFIRMATION} component={EmailConfirmationPage} />
          <Route exact path={URL_FORGOT_PASSWORD} component={ForgotPasswordPage} />
          <Route exact path={URL_RESET_PASSWORD} component={ResetPasswordPage} />

          {/* <Route component={Page404} /> */}
        </Switch>
      </BrowserRouter>
    </SocketContextProvider>
  );
};

export default Routes;
