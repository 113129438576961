import AdapterDayJS from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AuthContextProvider from './contexts/AuthContext';
import StatusContextProvider from './contexts/StatusContext';
import Routes from './routes/Routes';
import './App.css';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';

function App() {
  const theme = createTheme({
    palette: {
      type: 'light',
      primary: {
        main: '#585DBD',
      },
      background: {
        default: '#F0F1FA',
        primary: '#585DBD',
      },
    },
    shape: {
      borderRadius: 16,
    },
  });

  return (
    <>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDayJS}>
        <StatusContextProvider>
          <AuthContextProvider>
            <ThemeProvider theme={theme}>
              <Routes />
            </ThemeProvider>
          </AuthContextProvider>
        </StatusContextProvider>
      </LocalizationProvider>
    </>
  );
}

export default App;
