import React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { useHistory } from 'react-router-dom';
import { useData } from '../../contexts/DataContext';
import { useEffect } from 'react';
import { useRef } from 'react';

const SearchBar = () => {
  const { getRoutesData } = useData();
  const history = useHistory();
  const refSearchInput = useRef();

  useEffect(() => {
    const search = new URLSearchParams(history.location.search);
    refSearchInput.current.value = search.get('location');
  }, []);

  // ! for real-time search
  function handleSearch(e) {
    const search = new URLSearchParams(history.location.search);

    if (refSearchInput.current.value === '') {
      search.delete('location');
    } else {
      search.set('location', refSearchInput.current.value);
    }

    // search.set('page', 1);
    history.push(`${history.location.pathname}?${search.toString()}`);
    getRoutesData();
  }

  return (
    <Paper
      sx={{
        flexGrow: 1,
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '16px',
      }}
    >
      <InputBase
        inputRef={refSearchInput}
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search for routes"
        onChange={handleSearch}
        autoFocus
      />
      <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default SearchBar;
