import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

// ! https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
function updateVH() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  // alert(vh);
  const body = document.getElementsByTagName('body')[0];
  body.style.width = `${window.innerWidth}px`;
  body.style.height = `${window.innerHeight}px`;
}
updateVH();

// We listen to the resize event
window.addEventListener('resize', () => {
  updateVH();
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
