import React, { useEffect } from 'react';
import { useData } from '../../contexts/DataContext';
import NoRecordsMessage from '../utils/NoRecordsMessage';
import ReceivedRequestCard from './ReceivedRequestCard';

const ReceivedRequestsList = () => {
  const { getReceivedRequestsData, received_requests } = useData();

  useEffect(() => {
    getReceivedRequestsData();
  }, []);
  return (
    <>
      {received_requests && received_requests.length !== 0 ? (
        received_requests.map((item) => <ReceivedRequestCard key={item.id} item={item} />)
      ) : (
        <NoRecordsMessage />
      )}
    </>
  );
};

export default ReceivedRequestsList;
