import React from 'react';
import { Avatar, Box, IconButton, Paper, Typography } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Link } from 'react-router-dom';
import { URL_PROFILE_EDIT } from '../../helpers/consts';

const ProfilePreview = () => {
  const { user } = useAuth();

  function printUserName({ user_profile }) {
    if (user_profile) {
      return `${user.user_profile.last_name} ${user.user_profile.first_name}`;
    } else {
      return 'Ф. И.';
    }
  }

  return (
    <Paper
      sx={{
        padding: '16px 12px',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: 'inherit',
          }}
        >
          <Avatar sx={{ width: 44, height: 44, marginRight: '20px' }} />
          <Box sx={{ color: '#4D4C4C' }}>
            <Typography
              variant="body2"
              component="div"
              fontSize="0.8rem"
              sx={{ fontWeight: 'bold' }}
            >
              {printUserName(user)}
            </Typography>

            <Typography variant="body2" component="div" fontSize="0.8rem">
              {user.user_email}
            </Typography>
          </Box>
        </Box>
        <Link to={URL_PROFILE_EDIT}>
          <IconButton>
            <EditRoundedIcon sx={{ fontSize: 18 }} />
          </IconButton>
        </Link>
      </Box>
    </Paper>
  );
};

export default ProfilePreview;
