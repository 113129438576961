import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import SubmitButton from '../utils/SubmitButton';
import emailIcon from '../../assets/emailIcon.svg';
import { Stack } from '@mui/material';
import { URL_HOME } from '../../helpers/consts';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ForgotPasswordSent = () => {
  const history = useHistory();

  function handleRiderect() {
    history.push(URL_HOME);
  }

  return (
    <>
      <Container>
        <Stack
          direction={'column'}
          component={'form'}
          spacing={2}
          display={'flex'}
          alignItems={'center'}
          mt={6}
        >
          <Typography component="h1" variant="h6" sx={{ marginBottom: '30px' }}>
            Письмо отправлено на почту
          </Typography>
          <Box>
            <Avatar
              alt="email icon illustration"
              src={emailIcon}
              variant="square"
              sx={{ width: 200, height: 200, marginBottom: '20px' }}
            />
          </Box>
          <SubmitButton text={'Понятно'} handleClick={handleRiderect} />
        </Stack>
      </Container>
    </>
  );
};

export default ForgotPasswordSent;
