import React from 'react';
import { Box, Typography } from '@mui/material';
import { printTimestamp } from '../../helpers/utils';

const MessageBox = ({
  item: { chat_room_id, id, inserted_at, message, updated_at, user_id },
  isMyMessage,
}) => {
  const styles = {
    alignSelf: 'flex-start',
    padding: '8px 16px',
    backgroundColor: 'background.paper',
    borderRadius: '16px',
    borderBottomLeftRadius: 0,
    color: '#000000',
    textAlign: 'left',
    wordBreak: 'break-word',
    maxWidth: '80%',
  };

  if (isMyMessage) {
    styles.alignSelf = 'flex-end';
    styles.backgroundColor = 'primary.main';
    styles.color = '#FFFFFF';
    styles.borderBottomLeftRadius = '16px';
    styles.borderBottomRightRadius = 0;
    styles.textAlign = 'right';
  }

  return (
    <Box sx={styles}>
      <Typography variant="string" mr={1}>
        {message}
      </Typography>
      <Typography variant="caption" color="text.secondary" noWrap>
        {printTimestamp({ datetime: updated_at })}
      </Typography>
    </Box>
  );
};

export default MessageBox;
