import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Typography,
} from '@mui/material';
import { useData } from '../../contexts/DataContext';
import Box from '@mui/material/Box';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import RideTypeWalk from '../../assets/ride_type_walk.svg';
import RideTypeBus from '../../assets/ride_type_bus.svg';
import RideTypeTaxi from '../../assets/ride_type_taxi.svg';
import RideTypeCar from '../../assets/ride_type_car.svg';
import dayjs from 'dayjs';
import RouteCardValidityPeriod from '../utils/RouteCardValidityPeriod';
import { useHistory } from 'react-router-dom';
import { URL_CHAT } from '../../helpers/consts';
import RouteCardCircle from '../utils/RouteCardCircle';

const MiniRouteTypeIcon = ({ type }) => {
  let src;
  if (type === 'walk') src = 'rgba(163, 233, 170, 1)';
  if (type === 'bus') src = 'rgba(248, 171, 166, 1)';
  if (type === 'taxi') src = 'rgba(161, 159, 240, 1)';
  if (type === 'car') src = 'rgba(232, 177, 241, 1)';

  return (
    <Box backgroundColor={src} variant="square" sx={{ width: 10, height: 18, borderRadius: 1 }} />
  );
};

const RouteTypeIcon = ({ type }) => {
  let src;
  if (type === 'walk') src = RideTypeWalk;
  if (type === 'bus') src = RideTypeBus;
  if (type === 'taxi') src = RideTypeTaxi;
  if (type === 'car') src = RideTypeCar;

  return <Avatar src={src} variant="square" sx={{ width: 40, height: 28 }} />;
};

const ReceivedRequestCard = ({ item: { id, receiver_route, sender_route, status } }) => {
  const { acceptReceivedRequest, rejectReceivedRequest } = useData();
  const history = useHistory();

  const [statusBoolean, setStatusBoolean] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [statusCol, setStatusCol] = useState('');

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  function handleAccept() {
    acceptReceivedRequest(id);
  }
  function handleReject() {
    rejectReceivedRequest(id);
  }

  const getStatusColor = () => {
    if (status === 'pending') {
      setStatusCol('rgba(183, 179, 179, 1)');
    } else if (status === 'accepted') {
      setStatusCol('rgba(163, 233, 170, 1)');
    } else if (status === 'rejected') {
      setStatusCol('rgba(248, 171, 166, 1)');
    }
  };

  const btnReplace = () => {
    if (status === 'pending') {
      setStatusBoolean(false);
    } else if (status === 'accepted') {
      setStatusBoolean(true);
    } else if (status === 'rejected') {
      setStatusBoolean(false);
    }
  };

  useEffect(() => {
    getStatusColor();
    btnReplace();
  }, []);

  useEffect(() => {
    btnReplace();
  }, [status]);

  return (
    <Card sx={{ maxWidth: 360, margin: '0 auto 16px', borderRadius: '24px' }}>
      <CardContent
        onClick={function () {
          handleExpandClick();
        }}
        sx={{ paddingBottom: '5px' }}
      >
        <Box
          sx={{
            width: '100%',
            alignItems: 'center',
            paddingBottom: '5px',
          }}
        >
          <Typography variant="subtitle2" fontSize="0.65em">
            Вам предлагают маршрут
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle2" component="span" fontSize="1em">
            {sender_route.from_location}
          </Typography>
          <Typography
            variant="subtitle2"
            component="span"
            fontSize="1em"
            marginLeft="10px"
            color="#717370"
          >
            {sender_route.departure_time &&
              dayjs(`2000-01-01 ${sender_route.departure_time}`).format('HH:mm')}
          </Typography>
          {!expanded ? (
            <Box sx={{ display: 'flex', columnGap: '8px', marginLeft: '10px' }}>
              {sender_route.ride_types.map((t) => (
                <MiniRouteTypeIcon key={t} type={t} />
              ))}
            </Box>
          ) : (
            <></>
          )}
        </Box>
        <Box
          sx={{
            alignSelf: 'flex-start',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <RouteCardCircle variant="from" />
          <Box
            sx={{
              borderBottom: 'dotted 2px grey',
              width: '100%',
              height: '1px',
              mt: '3px',
              ml: '2px',
              mr: '2px',
            }}
          ></Box>
          <RouteCardCircle variant="to" />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: `${expanded ? 'space-between' : 'flex-end'}`,
            alignItems: 'center',
          }}
        >
          {expanded ? (
            <Box>
              <RouteCardValidityPeriod
                valid_from={sender_route.valid_from}
                valid_till={sender_route.valid_till}
              />
            </Box>
          ) : (
            <></>
          )}

          <Box>
            <Typography variant="subtitle2" component="span" fontSize="1em">
              {sender_route.to_location}
            </Typography>
            <Typography
              variant="subtitle2"
              component="span"
              fontSize="1em"
              marginLeft="10px"
              color="#717370"
            >
              {sender_route.arrival_time &&
                dayjs(`2000-01-01 ${sender_route.arrival_time}`).format('HH:mm')}
            </Typography>
          </Box>
        </Box>
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{ paddingTop: '5px', paddingBottom: '5px !important' }}>
          <Box sx={{ display: 'flex', columnGap: '8px' }}>
            {sender_route.ride_types.map((t) => (
              <RouteTypeIcon key={t} type={t} />
            ))}
          </Box>
          <Typography margin="5px 0" paragraph fontSize="0.8em">
            {sender_route.description}
          </Typography>
        </CardContent>
      </Collapse>
      <Box
        sx={{
          width: '100%',
          textAlign: 'center',
          backgroundColor: `${statusCol}`,
        }}
      >
        <Typography fontSize="1em" color="white">
          {status}
        </Typography>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '16px',
            columnGap: '16px',
          }}
        >
          <Button
            fullWidth
            type="submit"
            variant="outlined"
            color="secondary"
            onClick={handleReject}
          >
            {statusBoolean ? 'Отменить' : 'Отклонить'}
          </Button>
          {statusBoolean ? (
            <Button
              fullWidth
              variant="contained"
              startIcon={<ForumRoundedIcon />}
              onClick={() => history.push(URL_CHAT)}
            >
              в Чат
            </Button>
          ) : (
            <Button fullWidth variant="contained" color="primary" onClick={handleAccept}>
              Принять
            </Button>
          )}
        </Box>
      </Collapse>
      <CardContent
        onClick={function () {
          handleExpandClick();
        }}
        sx={{ paddingBottom: '5px' }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            paddingBottom: '5px',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="subtitle2" fontSize="0.65em">
            Ваш маршрут
          </Typography>
          <Typography variant="subtitle2" fontSize="0.65em" fontWeight="bold">
            {receiver_route.title}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle2" component="span" fontSize="1em">
            {receiver_route.from_location}
          </Typography>
          <Typography
            variant="subtitle2"
            component="span"
            fontSize="1em"
            marginLeft="10px"
            color="#717370"
          >
            {receiver_route.departure_time &&
              dayjs(`2000-01-01 ${receiver_route.departure_time}`).format('HH:mm')}
          </Typography>
          {!expanded ? (
            <Box sx={{ display: 'flex', columnGap: '8px', marginLeft: '10px' }}>
              {receiver_route.ride_types.map((t) => (
                <MiniRouteTypeIcon key={t} type={t} />
              ))}
            </Box>
          ) : (
            <></>
          )}
        </Box>
        <Box
          sx={{
            alignSelf: 'flex-start',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <RouteCardCircle variant="from" />
          <Box
            sx={{
              borderBottom: 'dotted 2px grey',
              width: '100%',
              height: '1px',
              mt: '3px',
              ml: '2px',
              mr: '2px',
            }}
          ></Box>
          <RouteCardCircle variant="to" />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: `${expanded ? 'space-between' : 'flex-end'}`,
            alignItems: 'center',
          }}
        >
          {expanded ? (
            <Box>
              <RouteCardValidityPeriod
                valid_from={receiver_route.valid_from}
                valid_till={receiver_route.valid_till}
              />
            </Box>
          ) : (
            <></>
          )}

          <Box>
            <Typography variant="subtitle2" component="span" fontSize="1em">
              {receiver_route.to_location}
            </Typography>
            <Typography
              variant="subtitle2"
              component="span"
              fontSize="1em"
              marginLeft="10px"
              color="#717370"
            >
              {receiver_route.arrival_time &&
                dayjs(`2000-01-01 ${receiver_route.arrival_time}`).format('HH:mm')}
            </Typography>
          </Box>
        </Box>
      </CardContent>
      <CardActions></CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{ paddingTop: '5px', paddingBottom: '5px !important' }}>
          <Box sx={{ display: 'flex', columnGap: '8px' }}>
            {receiver_route.ride_types.map((t) => (
              <RouteTypeIcon key={t} type={t} />
            ))}
          </Box>
          <Typography margin="5px 0" paragraph fontSize="0.8em">
            {receiver_route.description}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default ReceivedRequestCard;
