import React from 'react';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';

const RouteCardValidityPeriod = ({ valid_from, valid_till }) => {
  return (
    <>
      {(valid_from || valid_till) && (
        <Typography variant="string" fontSize="0.75em" color="text.disabled">
          активен
        </Typography>
      )}

      {valid_from && (
        <>
          <Typography variant="string" fontSize="0.75em" color="text.disabled" mx={0.5}>
            с
          </Typography>
          <Typography variant="string" fontSize="0.85em">
            {dayjs(valid_from).format('MMM DD')}
          </Typography>
        </>
      )}
      {valid_till && (
        <>
          <Typography variant="string" fontSize="0.75em" color="text.disabled" mx={0.5}>
            до
          </Typography>
          <Typography variant="string" fontSize="0.85em">
            {dayjs(valid_till).format('MMM DD')}
          </Typography>
        </>
      )}
    </>
  );
};

export default RouteCardValidityPeriod;
