import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import CustomTextField from './CustomTextField';

const RouteCardLocations = ({ fromLocation, toLocation, readOnly = true, hideLabels }) => {
  const FromLocation = (
    <Box>
      {hideLabels ? null : (
        <Typography fontSize="0.75em" color="text.disabled">
          Откуда
        </Typography>
      )}
      <Typography noWrap>{fromLocation}</Typography>
    </Box>
  );

  const ToLocation = (
    <Box>
      {hideLabels ? null : (
        <Typography fontSize="0.75em" color="text.disabled">
          Куда
        </Typography>
      )}
      <Typography noWrap>{toLocation}</Typography>
    </Box>
  );

  const FromLocationEditable = (
    <CustomTextField
      label="Откуда"
      variant="standard"
      name="from_location"
      fullWidth
      required
      focused
    />
  );

  const ToLocationEditable = (
    <CustomTextField
      label="Куда"
      variant="standard"
      name="to_location"
      fullWidth
      required
      focused
    />
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        minWidth: 0,
        display: 'flex',
        flexDirection: 'column',
        rowGap: readOnly ? '8px' : '12px',
      }}
    >
      {readOnly ? FromLocation : FromLocationEditable}
      {readOnly ? <Divider /> : null}
      {readOnly ? ToLocation : ToLocationEditable}
    </Box>
  );
};

export default RouteCardLocations;
