import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import { STYLES_BORDER_RADIUS } from '../../helpers/styles';
import RouteCardPathDecorator from '../utils/RouteCardPathDecorator';
import RouteCardLocations from '../utils/RouteCardLocations';
import RideTypesBar from '../utils/RideTypesBar';
import SubmitButton from '../utils/SubmitButton';
import RouteCardValidityPeriod from '../utils/RouteCardValidityPeriod';
import DayOfWeekBar from '../utils/DayOfWeekBar';
import RouteCardTimeSchedule from '../utils/RouteCardTimeSchedule';

const RoutesCardMini = ({
  item: {
    arrival_time,
    departure_time,
    description,
    dow,
    from_location,
    id,
    is_active,
    money,
    ride_types,
    title,
    to_location,
    user_id,
    valid_from,
    valid_till,
  },
  handleSubmit,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ maxWidth: 360, margin: '0 auto 16px', borderRadius: STYLES_BORDER_RADIUS }}>
      <CardContent onClick={handleExpandClick}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', columnGap: '16px' }}>
          <RouteCardPathDecorator />
          <RouteCardLocations
            hideLabels={true}
            fromLocation={from_location}
            toLocation={to_location}
          />
          <RouteCardTimeSchedule departure_time={departure_time} arrival_time={arrival_time} />
        </Box>
      </CardContent>
      <CardActions sx={{ padding: 0 }}></CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '16px', mb: '16px' }}>
            <Box>
              <RideTypesBar rideTypes={ride_types} readOnly={true} />
              <Box sx={{ mt: '8px' }}>
                <RouteCardValidityPeriod valid_from={valid_from} valid_till={valid_till} />
                <DayOfWeekBar checkedState={dow} />
              </Box>
            </Box>
          </Box>
          <SubmitButton text={'Отправить'} handleClick={() => handleSubmit(id)} />
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default RoutesCardMini;
