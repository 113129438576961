import React from 'react';
import { Box, useTheme } from '@mui/material';
import FilterBar from '../utils/FilterBar';
import SearchBar from '../utils/SearchBar';
import { STYLES_SEARCH_BAR_HEIGHT } from '../../helpers/styles';

const RoutesListHeader = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: STYLES_SEARCH_BAR_HEIGHT,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px',
        backgroundColor: 'background.default',
      }}
    >
      <SearchBar />
      <FilterBar />
    </Box>
  );
};

export default RoutesListHeader;
