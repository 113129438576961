import React from 'react';
import { AppBar, IconButton, Paper, Slide, Typography, useScrollTrigger } from '@mui/material';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { useHistory } from 'react-router-dom';
import { STYLES_APP_MAX_WIDTH, STYLES_HEADER_HEIGHT } from '../helpers/styles';

const LayoutHeader = ({ title, goBack, component, triggerTarget }) => {
  const history = useHistory();

  const GoBackButton = (
    <IconButton onClick={history.goBack} sx={{ position: 'absolute', left: '0px' }}>
      <ArrowBackIosNewRoundedIcon />
    </IconButton>
  );

  function HideOnScroll({ triggerOptions = {}, children }) {
    // const trigger = useScrollTrigger({
    //   target: triggerTargetRef.current.childNodes[0].childNodes[0],
    // });
    const trigger = useScrollTrigger(triggerOptions);

    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  }

  const HeaderAppBar = (
    <AppBar
      // position="absolute"
      color="transparent"
      elevation={0}
      sx={{ width: '100%', maxWidth: STYLES_APP_MAX_WIDTH, right: 'auto' }}
    >
      {goBack && GoBackButton}
      {component ? (
        component
      ) : (
        <Paper
          elevation={0}
          sx={{
            borderRadius: 0,
            height: STYLES_HEADER_HEIGHT,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography align="center" variant="h6">
            {title}
          </Typography>
        </Paper>
      )}
    </AppBar>
  );

  if (triggerTarget) {
    return (
      <HideOnScroll
        triggerOptions={{
          target: triggerTarget,
        }}
      >
        {HeaderAppBar}
      </HideOnScroll>
    );
  } else {
    return <HideOnScroll>{HeaderAppBar}</HideOnScroll>;
  }
};

export default LayoutHeader;
