import React, { useRef } from 'react';
import { useParams } from 'react-router';
import { useAuth } from '../../contexts/AuthContext';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Stack } from '@mui/material';
import SubmitButton from '../utils/SubmitButton';
import { useStatus } from '../../contexts/StatusContext';
import SpinnerCover from '../utils/SpinnerCover';
import { useHistory } from 'react-router-dom';
import { URL_LOGIN } from '../../helpers/consts';

const ResetPasswordForm = () => {
  const { token } = useParams();
  const { resetPassword } = useAuth();
  const { loading } = useStatus();
  const formRef = useRef();
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();
    const {
      current: { password, repeat_password },
    } = formRef;

    if (password.value.length < 8) {
      alert('Минимальное кол-во символов для пароля 8');
      return;
    }

    if (password.value !== repeat_password.value) {
      alert('Пароли не совпадают! Введите заново');
      return;
    }

    const formData = {
      token,
      password: password.value,
      repeat_password: repeat_password.value,
    };
    await resetPassword(formData);
    history.push(URL_LOGIN);
  }

  return (
    <>
      <Container>
        <Stack
          ref={formRef}
          component={'form'}
          direction={'column'}
          spacing={2}
          display={'flex'}
          alignItems={'center'}
          mt={6}
        >
          <Avatar sx={{ m: 2, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Изменение пароля
          </Typography>

          <TextField
            id="password"
            name="password"
            type="password"
            label="Введите новый пароль"
            autoComplete="new-password"
            required
            fullWidth
          />
          <TextField
            id="repeat_password"
            name="repeat_password"
            type="password"
            label="Повторите пароль"
            autoComplete="new-password"
            required
            fullWidth
          />
          <SubmitButton text={'Сохранить'} handleClick={handleSubmit} />
        </Stack>
      </Container>
      {loading && <SpinnerCover />}
    </>
  );
};

export default ResetPasswordForm;
