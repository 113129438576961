import React, { useEffect, useRef, useState } from 'react';
import { Global } from '@emotion/react';
import dayjs from 'dayjs';
import { Box, InputAdornment, Stack, SwipeableDrawer, TextField } from '@mui/material';
import DayOfWeekBar from '../utils/DayOfWeekBar';
import Puller from '../utils/Puller';
import RideTypesBar from '../utils/RideTypesBar';
import RouteCardLocations from '../utils/RouteCardLocations';
import RouteCardPathDecorator from '../utils/RouteCardPathDecorator';
import RouteDatePicker from '../utils/RouteDatePicker';
import RouteTimePicker from '../utils/RouteTimePicker';
import SubmitButton from '../utils/SubmitButton';

const drawerOverflowHeight = 155;

const RouteForm = ({ route, handleSubmit, submitText }) => {
  const [rideTypes, setRideTypes] = useState({});
  const [dow, setDOW] = useState({});
  const [timeDeparture, setTimeDeparture] = useState(null);
  const [timeArrival, setTimeArrival] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [open, setOpen] = useState(false);

  const refLocationsForm = useRef(null);
  const refForm = useRef(null);

  useEffect(() => {
    if (route && refLocationsForm.current) {
      fillHTMLForm();
    }
  }, [route, refLocationsForm.current]);

  function fillHTMLForm() {
    refLocationsForm.current.from_location.value = route.from_location;
    refLocationsForm.current.to_location.value = route.to_location;

    refForm.current.title.value = route.title;
    refForm.current.description.value = route.description;
    refForm.current.money.value = route.money;

    setRideTypes(
      route.ride_types.reduce((acc, type) => {
        return { ...acc, [type]: true };
      }, {})
    );

    setDOW(
      route.dow.split('').reduce((acc, type) => {
        return { ...acc, [type]: true };
      }, {})
    );

    if (route.departure_time) {
      setTimeDeparture(new Date(2021, 12, 31, ...route.departure_time.split(':')));
    } else {
      setTimeDeparture(route.departure_time);
    }
    if (route.arrival_time) {
      setTimeArrival(new Date(2021, 12, 31, ...route.arrival_time.split(':')));
    } else {
      setTimeArrival(route.arrival_time);
    }

    setDateFrom(route.valid_from);
    setDateTo(route.valid_till);
  }

  function identifyDOW(obj) {
    const arr = [];
    Object.entries(obj).forEach(([key, val]) => val && arr.push(key));
    return arr.join('');
  }

  function identifyRideTypes(obj) {
    const arr = [];
    Object.entries(obj).forEach(([key, val]) => val && arr.push(key));
    return arr;
  }

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  function collectAndSubmitForm(e) {
    e.preventDefault();

    if (refLocationsForm.current.from_location.value === '')
      return alert('Пожалуйста укажите начальную точку вашего маршрута');
    if (refLocationsForm.current.to_location.value === '')
      return alert('Пожалуйста укажите конечную точку вашего маршрута');

    const rideTypesArr = identifyRideTypes(rideTypes);
    if (rideTypesArr.length === 0) return alert('Выберите тип транспорта для вашего маршрута');

    const dowStr = identifyDOW(dow);
    if (dowStr === '') return alert('Выберите дни недели актуальные для вашего маршрута');

    if (timeDeparture === undefined)
      return alert('Пожалуйста укажите ориентировочное время отправления');

    const formObj = {
      from_location: refLocationsForm.current.from_location.value,
      to_location: refLocationsForm.current.to_location.value,
      departure_time: timeDeparture,
      arrival_time: timeArrival,
      ride_types: rideTypesArr,
      dow: dowStr,
      valid_from: dateFrom,
      valid_till: dateTo,
      is_active: true,
    };
    formObj.title = refForm.current.title?.value || '';
    formObj.description = refForm.current.description?.value || '';
    formObj.money = refForm.current.money?.value || null;
    if (formObj.money) formObj.money = parseInt(formObj.money);
    if (formObj.departure_time)
      formObj.departure_time = dayjs(formObj.departure_time).format('HH:mm');
    if (formObj.arrival_time) formObj.arrival_time = dayjs(formObj.arrival_time).format('HH:mm');

    handleSubmit(formObj);
  }

  return (
    <>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(100% - ${drawerOverflowHeight}px - 40px)`,
            overflow: 'visible',
          },
        }}
      />
      <Box sx={{ position: 'absolute', bottom: drawerOverflowHeight, left: 0, right: 0 }}>
        <Puller backgroundColor="background.primary" onClick={toggleDrawer(true)} />
      </Box>

      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerOverflowHeight}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box
          sx={{
            height: '100%',
            position: 'absolute',
            top: -drawerOverflowHeight,
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
            visibility: 'visible',
            right: 0,
            left: 0,
            pl: '16px',
            pr: '16px',
            backgroundColor: 'background.paper',
          }}
        >
          <Puller
            onClick={toggleDrawer(false)}
            backgroundColor={open ? 'background.default' : 'background.paper'}
          />

          {/* // ! Level 1 */}
          <Box
            ref={refLocationsForm}
            component="form"
            sx={{ display: 'flex', justifyContent: 'space-between', columnGap: '16px' }}
          >
            <RouteCardPathDecorator />
            <RouteCardLocations readOnly={false} />
          </Box>
        </Box>

        <Box
          sx={{
            px: 2,
            pb: 2,
            height: '100%',
            overflow: 'auto',
          }}
        >
          {/* <Skeleton variant="rectangular" height="100%" /> */}

          <Stack component="form" ref={refForm} spacing={2} sx={{ pb: '8px', overflowY: 'scroll' }}>
            {/* // ! Level 2 */}
            <>
              {/* // ! Ride types */}
              <RideTypesBar
                readOnly={false}
                checkedState={rideTypes}
                handleChange={setRideTypes}
                justifyContent="space-between"
              />
              {/* // ! Depart & Arrival Time */}
              <Stack direction="row" spacing={3} justifyContent="space-between">
                <RouteTimePicker
                  label="Время отправления"
                  value={timeDeparture}
                  setValue={setTimeDeparture}
                />
                <RouteTimePicker
                  label="Время прибытия"
                  value={timeArrival}
                  setValue={setTimeArrival}
                />
              </Stack>
              {/* // ! Days Of Week */}
              <DayOfWeekBar readOnly={false} checkedState={dow} handleChange={setDOW} />
            </>

            {/* // ! Level 3 */}
            <>
              {/* // ! Route name */}
              <TextField
                label="Название"
                placeholder="Для личного пользования"
                variant="outlined"
                name="title"
                fullWidth
              />
              {/* // ! Route Description */}
              <TextField
                variant="outlined"
                name="description"
                label="Описание маршрута"
                placeholder="Дополнительна информация если имеется..."
                multiline
                maxRows={3}
                fullWidth
              />
              {/* // ! Budget to share */}
              <TextField
                label="Бюджет"
                name="money"
                type="number"
                placeholder="Любой или укажите в цифрах"
                InputProps={{
                  step: 1,
                  min: 1,
                  endAdornment: <InputAdornment position="end">KGS</InputAdornment>,
                }}
              />
              {/* // ! Validity Period */}
              <Stack direction="row" spacing={3} justifyContent="space-between">
                <RouteDatePicker label="Активен с даты" value={dateFrom} setValue={setDateFrom} />
                <RouteDatePicker label="Активен до даты" value={dateTo} setValue={setDateTo} />
              </Stack>
            </>

            {/* // ! Submit button */}
            <SubmitButton text={submitText} handleClick={collectAndSubmitForm} />
          </Stack>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default RouteForm;
