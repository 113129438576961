import React from 'react';
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';
import MobileDatePicker from '@mui/lab/MobileDatePicker';

const RouteDatePicker = ({ label, value, setValue }) => {
  return (
    <MobileDatePicker
      label={label}
      openTo="year"
      allowSameDateSelection={true}
      inputFormat="DD-MMM-YYYY"
      views={['year', 'month', 'day']}
      value={value}
      onChange={setValue}
      renderInput={(params) => <TextField {...params} />}
      okText={'Применить'}
      cancelText={'Оменить'}
      clearable={true}
      clearText={'Очистить'}
      showToolbar={true}
    />
  );
};

export default RouteDatePicker;
