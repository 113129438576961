// Resources to read about phoenix socket https://hexdocs.pm/phoenix/js/
import { Socket } from 'phoenix';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { OPEN_SOCKET, WS_URL } from '../helpers/consts';
import { useAuth } from './AuthContext';

const socketContext = createContext();

export const useSocket = () => {
  return useContext(socketContext);
};

const INIT_STATE = {
  socket: null,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case OPEN_SOCKET:
      return {
        ...state,
        socket: action.payload,
      };
    default:
      return state;
  }
};

const SocketContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INIT_STATE);
  const { user, attachAuth } = useAuth();

  // we open socket as soon as App.js mounts
  useEffect(() => {
    openSocket();
  }, [user]);

  async function openSocket() {
    let token = undefined;
    // let token = user?.tokens?.access_token;
    if (user) {
      const header = await attachAuth();
      token = header['Authorization'];
    }
    const socket = new Socket(`${WS_URL}/socket`, {
      params: { token: token },
    });
    socket.connect();
    dispatch({
      type: OPEN_SOCKET,
      payload: socket,
    });
  }

  const values = {
    socket: state.socket,
  };

  return <socketContext.Provider value={values}>{children}</socketContext.Provider>;
};

export default SocketContextProvider;
