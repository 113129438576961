import React from 'react';
import RouteCardCircle from './RouteCardCircle';
import RouteCardDottedLine from './RouteCardDottedLine';

const RouteCardPathDecorator = () => {
  return (
    <RouteCardDottedLine
      lineStart={<RouteCardCircle variant="from" />}
      lineEnd={<RouteCardCircle variant="to" />}
    />
  );
};

export default RouteCardPathDecorator;
