import React from 'react';
import dayjs from 'dayjs';
import { Box } from '@mui/material';
import RouteCardDottedLine from './RouteCardDottedLine';

const RouteCardTimeSchedule = ({ departure_time, arrival_time }) => {
  const DepartureTimeBlock = (
    <Box sx={{ alignSelf: 'flex-start', position: 'relative' }}>
      {dayjs(`2000-01-01 ${departure_time}`).format('HH:mm')}
    </Box>
  );

  const ArrivalTimeBlock = arrival_time ? (
    <Box sx={{ alignSelf: 'flex-end' }}>{dayjs(`2000-01-01 ${arrival_time}`).format('HH:mm')}</Box>
  ) : null;

  return <RouteCardDottedLine lineStart={DepartureTimeBlock} lineEnd={ArrivalTimeBlock} />;
};

export default RouteCardTimeSchedule;
