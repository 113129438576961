import React, { useRef } from 'react';
import RoutesList from '../../components/routes/RoutesList';
import RoutesListHeader from '../../components/routes/RoutesListHeader';
import { STYLES_SEARCH_BAR_HEIGHT } from '../../helpers/styles';
import LayoutContent from '../../layouts/LayoutContent';
import LayoutFooter from '../../layouts/LayoutFooter';
import LayoutHeader from '../../layouts/LayoutHeader';
import LayoutWrapper from '../../layouts/LayoutWrapper';

const RoutesPage = () => {
  const contentRef = useRef();

  return (
    <LayoutWrapper>
      <LayoutHeader component={<RoutesListHeader />} triggerTarget={contentRef.current} />
      <LayoutContent headerHeight={STYLES_SEARCH_BAR_HEIGHT} forwardRef={contentRef}>
        <RoutesList />
      </LayoutContent>
      <LayoutFooter />
    </LayoutWrapper>
  );
};

export default RoutesPage;
