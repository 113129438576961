import React, { useRef } from 'react';
import LayoutWrapper from '../../layouts/LayoutWrapper';
import ChatList from '../../components/chat/ChatList';
import LayoutHeader from '../../layouts/LayoutHeader';
import LayoutContent from '../../layouts/LayoutContent';
import LayoutFooter from '../../layouts/LayoutFooter';

const ChatsPage = () => {
  const contentRef = useRef();

  return (
    <LayoutWrapper>
      <LayoutHeader title={'Чаты'} triggerTarget={contentRef.current} />
      <LayoutContent pl={0} pr={0} forwardRef={contentRef}>
        <ChatList />
      </LayoutContent>
      <LayoutFooter />
    </LayoutWrapper>
  );
};

export default ChatsPage;
