import React, { useEffect } from 'react';
import { Avatar, Box, Container, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { useAuth } from '../../contexts/AuthContext';
import { useStatus } from '../../contexts/StatusContext';
import { URL_LOGIN, URL_REGISTRATION } from '../../helpers/consts';
import SpinnerCover from '../utils/SpinnerCover';
import emailConfirmed from '../../assets/emailConfirmed.svg';
import oops from '../../assets/oops.svg';

import SubmitButton from '../utils/SubmitButton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const EmailConfirmation = () => {
  const { loading } = useStatus();
  const { token } = useParams();
  const history = useHistory();
  const { email_confirmed, confirmEmail } = useAuth();

  useEffect(() => {
    confirmEmail(token);
  }, []);

  function handleRedirect(path) {
    history.push(path);
  }

  return (
    <>
      <Container>
        {email_confirmed ? (
          <Stack
            direction={'column'}
            component={'form'}
            spacing={2}
            display={'flex'}
            alignItems={'center'}
            mt={6}
          >
            <Typography
              component="h1"
              variant="h6"
              sx={{ marginBottom: '30px', textAlign: 'center' }}
            >
              Адрес электронной почты подтверждён
            </Typography>
            <Box>
              <Avatar
                alt="email icon illustration"
                src={emailConfirmed}
                variant="square"
                sx={{ width: 200, height: 200, marginBottom: '20px' }}
              />
            </Box>
            <SubmitButton text={'Перейти к Входу'} handleClick={() => handleRedirect(URL_LOGIN)} />
          </Stack>
        ) : (
          <Stack
            direction={'column'}
            component={'form'}
            spacing={2}
            display={'flex'}
            alignItems={'center'}
            mt={6}
          >
            <Typography component="h1" variant="h6" sx={{ marginBottom: '30px' }}>
              Упс... Что то пошло не так
            </Typography>
            <Box>
              <Avatar
                alt="email icon illustration"
                src={oops}
                variant="square"
                sx={{ width: 250, height: 200, marginBottom: '20px' }}
              />
            </Box>
            <SubmitButton text={'Понятно'} handleClick={() => handleRedirect(URL_REGISTRATION)} />
          </Stack>
        )}
        {loading && <SpinnerCover />}
      </Container>
    </>
  );
};

export default EmailConfirmation;
