import React from 'react';
import AlertsBar from '../components/utils/AlertsBar';
import DataContextProvider from '../contexts/DataContext';

const LayoutWrapper = ({ children }) => {
  return (
    <DataContextProvider>
      <AlertsBar />
      {children}
    </DataContextProvider>
  );
};

export default LayoutWrapper;
